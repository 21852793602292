import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { Link } from "react-router-dom";
import MentionText from "../../Utils/MentionText";
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import bannerUnauthLight from "../../../assets/bannerLight.png";
import bannerUnauthDark from "../../../assets/bannerDark.png";
import { truncateString } from "../../../utils/textAnalyser/truncateString";
import { withNamespaces } from "react-i18next";

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
  display: flex;
`;

const CardContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    max-height: 365px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Banner = styled.div`
  max-width: 100%;
  height: 250px;
  background-image: url(${(props) => props.bannerUrl});
  background-size: cover;
  background-position: center;
`;

const ProfileImage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 10px;
    transform: translateY(-50%); 
`;

const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
    gap: 5px;
`;

const UserName = styled.div`
    margin: 0;
    font-weight: 600;
    font-size: 1.2rem;
`;

const Bio = styled.p`
    margin: 0;
    font-size: 0.8rem;
`;

const ProfileHeader = styled.div`
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`

const LeftHeader = styled.div`
    display: flex;
    gap: 10px;
`

const RightHeader = styled.div`
    display: flex;
    padding-right: 10px;
    gap: 10px;
`

const ClassicButton = styled.button`
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;
  height: 40px;
  font-weight: 500;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled(ClassicButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color: ${colors.primary};

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

const Count = styled.div`
  font-size: 0.8rem;
`

const SpanText = styled.span`
    color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    font-size: 1.2rem;
`

const SpanCount = styled(SpanText)`
    font-size: 0.8rem;
`

function ClubCard({ key, club, avatar, banner, created_at, description, isMember, members_count, owner_id, rank, t }) {

  const { theme } = useTheme();

  return (
    <StyledLink to={`/club/${club}`} theme={theme}>
      <CardContainer theme={theme}>
            <Banner bannerUrl={banner ? banner : (theme === 'light' ? bannerUnauthLight : bannerUnauthDark)} alt={"Club's Banner"} />
            <ProfileHeader>
                <LeftHeader>
                    <ProfileImage src={avatar ? avatar : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)} alt="Profile" />
                    <ProfileDetails theme={theme}>
                        <UserName><SpanText theme={theme}>/{club}</SpanText></UserName>
                        <Bio>
                            {description ? <MentionText text={truncateString(description, 120)} /> : 'No description available.'}
                        </Bio>
                        <Count><SpanCount theme={theme}>{members_count}</SpanCount> members</Count>
                    </ProfileDetails>
                </LeftHeader>
                <RightHeader>
                    { isMember ? (
                      club !== "everyone" ? (
                        <ClassicButton theme={theme}>{t('club.header.leave')}</ClassicButton>
                      ) : (
                        <ActiveButton theme={theme}>Everyone</ActiveButton>
                      )
                    ) : (
                      <ActiveButton theme={theme}>{t('club.header.join')}</ActiveButton>
                    )}
                </RightHeader>
            </ProfileHeader>
        </CardContainer>
    </StyledLink>
  );
}

export default withNamespaces()(ClubCard);