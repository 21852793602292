import PropTypes from "prop-types";
import {
  FiMessageSquare,
  FiHeart,
  FiCornerUpRight,
  FiTrash2,
} from "react-icons/fi";
import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { useState, useEffect } from "react";
import CommentComposer from "../../Comment/CommentComposer";
import { useAuth } from "../../../utils/hooks/useAuth";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { withNamespaces } from "react-i18next";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const ADDLIKE_URL = "api/like/addLike";
const REMOVELIKE_URL = "api/like/removeLike";

const PostActionsContainer = styled.ul`
  list-style: none;
  display: flex;
  justify-content: left;
  padding: 0;
  gap: 5px;
`;

const PostActionsButton = styled.button`
  font-size: 0.7rem;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  background: none;
  border: 0;
  padding: 8px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  cursor: pointer;
`;

const PostActionsWrapper = styled.div`
  padding-left: 10px;
  margin-bottom: 15px;
  background-color: ${({theme}) => theme === 'light' ? colors.primaryBackground : colors.secondaryBackground};
  border: 1px solid ${({theme}) => theme  === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-top: none;
  border-bottom-right-radius:10px;
  border-bottom-left-radius:10px;
  width: 520;
  box-sizing: border-box;
`;

const RepliesToText = styled.div`
  padding-left: 10px;
  font-size: 0.8rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  font-style: italic;
`;

const LikeButton = styled(FiHeart)`
  fill: red;
  color: red;
`;

const UnlikeButton = styled(FiHeart)`
  &:hover {
    fill: red;
    color: red;
  }
`;

const TrashIcon = styled(FiTrash2)`
  &:hover {
    color: red;
  }
`

const DeleteWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`

const DeleteMessage = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  text-align: center;
`

const RedText = styled.span`
  color: red;
  font-size: 0.8rem;
`

const DeleteButton = styled.button`
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
      background-color: red;
    }
`;

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`

const LikesLink = styled(Link)`
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  text-decoration: none;
  font-size: 0.8rem;

  &:hover{
    text-decoration: underline;
  }
`

const LiFormat = styled.li`
  display: flex;
  align-items: center;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  font-size: 0.8rem;
`


function PostActions({ counters, onPostSubmit, onPostDelete, t}) {
  const { theme } = useTheme();
  const { auth } = useAuth();

  const axiosPrivate = useAxiosPrivate();

  const [commentStatus, toggleCommentStatus] = useState(false);
  const [deleteStatus, toggleDeleteStatus] = useState(false);
  const [likeCount, setLikeCount] = useState(counters.like_count);
  const [commentCount, setCommentCount] = useState(counters.comment_count);
  const [repostCount, setRepostCount] = useState(counters.repost_count);
  const [isLiked, setIsLiked] = useState(counters.isLiked);
  const [club, setClub] = useState(counters.club);

  const DELETE_POST_URL = `/api/post/deletePostById/${counters.post_id}`; 

  useEffect(() => {
    setLikeCount(counters.like_count);
  }, [counters.like_count]);

  useEffect(() => {
    setCommentCount(counters.comment_count);
  }, [counters.comment_count]);

  useEffect(() => {
    setRepostCount(counters.repost_count);
  }, [counters.repost_count]);

  useEffect(() => {
    setIsLiked(counters.isLiked);
  }, [counters.isLiked]);

  useEffect(() => {
    toggleCommentStatus(false);
    toggleDeleteStatus(false);
  }, [])

  function switchCommentStatus() {
    toggleDeleteStatus(false);
    toggleCommentStatus((prevStatus) => !prevStatus);
  }

  function switchDeleteStatus(){
    toggleCommentStatus(false);
    toggleDeleteStatus((prevStatus) => !prevStatus);
  }

  function toggleLike() {
    if (isLiked === 0) {
      setIsLiked(1);

      if (auth.username) {
        try {
          setLikeCount(likeCount + 1);

          axiosPrivate.post(ADDLIKE_URL, {
            post_id: counters.post_id,
            username: auth.username,
          });
        } catch (err) {
          console.error("Erreur lors de la requête :", err);
        }
      }
    } else {
      setIsLiked(0);

      if (auth.username) {
        try {
          setLikeCount(likeCount - 1);

          axiosPrivate.post(REMOVELIKE_URL, {
            post_id: counters.post_id,
            username: auth.username,
          });
        } catch (err) {
          console.error("Erreur lors de la requête :", err);
        }
      }
    }
  }

  async function deletePost() {
    if (!counters.post_id) {
      console.error('Post ID is required for deletion.');
      return;
    }

    try {

      console.log(counters.parent_id);
      const response = await axiosPrivate.post(DELETE_POST_URL, {
        post_id: counters.post_id, 
        parent_id: counters.parent_id,
      });

      if (response.status === 200) {
        console.log('Post deleted successfully.');
        onPostDelete();
        toggleDeleteStatus();
      } else {
        console.error('Failed to delete post:', response);
      }
    } catch (err) {
      console.error('Error while deleting post:', err);
    }
  }

  return (
    <PostActionsWrapper level={counters.level} theme={theme}>
      <PostActionsContainer>
        <LiFormat theme={theme}>
          <LikesLink theme={theme} to={`../../${counters.post_id}/details`}>{likeCount}</LikesLink>
          <PostActionsButton theme={theme} onClick={toggleLike} color={counters.color}>
            {isLiked ? <LikeButton size="18" /> : <UnlikeButton size="18" />}
          </PostActionsButton>
        </LiFormat>
        <LiFormat theme={theme} to="">
          {commentCount}
          <PostActionsButton theme={theme} onClick={switchCommentStatus}>
            <FiMessageSquare size="18" />
          </PostActionsButton>
        </LiFormat>
        <LiFormat theme={theme}>
          {repostCount}
          <PostActionsButton theme={theme}>
            <FiCornerUpRight size="18" />
          </PostActionsButton>
        </LiFormat>
        {auth.username && auth.username === counters.username && (
          <LiFormat theme={theme}>
            <PostActionsButton theme={theme} onClick={switchDeleteStatus}>
              <TrashIcon size="18" />
            </PostActionsButton>
          </LiFormat>
        )}
      </PostActionsContainer>
      {commentStatus && (
        <div>
          <RepliesToText>
            Replying to @{counters.username}
          </RepliesToText>
          <CommentComposer
            onPostSubmit={onPostSubmit}
            counters={{
              post_id: counters.post_id,
              owner: counters.username,
              text: "Post your reply !",
              color: counters.color,
              club: counters.club
            }}
          />
        </div>
      )}
      {deleteStatus && (
          <DeleteWrapper>
              <DeleteMessage>
                <Trans i18nKey="posts.delete.message">
                  Do you want to <RedText>delete</RedText> this post forever ?
                </Trans>
              </DeleteMessage>
              <Buttons>
                <DeleteButton theme={theme} onClick={deletePost}>{t('posts.delete.button')}</DeleteButton>
              </Buttons>
          </DeleteWrapper>
      )}
    </PostActionsWrapper>
  );
}

PostActions.propTypes = {
  counters: PropTypes.shape({
    comments: PropTypes.number,
    retweets: PropTypes.number,
    favorites: PropTypes.number,
  })
};

export default withNamespaces()(PostActions);
