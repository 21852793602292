import React from 'react';
import styled from 'styled-components';
import colors from "../../utils/style/colors";
import { useTheme } from '../../utils/hooks/useTheme';

const IconWrapper = styled.div`
  display: flex;
  padding:5px ;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  width: 30px;
  height: 30px;
  font-size: 1rem;
  flex-shrink: 0;

  &:hover {
    background-color: ${({ theme }) => theme === "light" ? colors.primaryHover : colors.secondaryHover};
  }
`;

function Icon({ children }) {
  const { theme } = useTheme();

  return (
      <IconWrapper theme={theme}>
          {children}
      </IconWrapper>
  );
}

export default Icon;