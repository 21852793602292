import { useTheme } from "../../../utils/hooks/useTheme";
import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { FiArrowLeft} from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";

const LeftArrowIcon = styled(FiArrowLeft)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const Mention = styled.div`
  padding-left: 10px;
  font-size: 0.8rem;
`

const NavContainer = styled.div`
  border-bottom: none;
  display: flex;
  align-items: center;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 50px;
`

const TopContainer = styled.div`
  padding: 5px;
  display: flex;
  align-items: center;
`

function NavHeader({mention}){

    const navigate = useNavigate();
    const {theme} = useTheme();

    const handleGoBack = () => {
        navigate(-1);
    };

    return (
        <NavContainer theme={theme}>
          <TopContainer>
            <Icon><LeftArrowIcon theme={theme} onClick={handleGoBack}/></Icon>
            <Mention>{mention}</Mention>
          </TopContainer>
        </NavContainer>
    )
};

export default NavHeader;