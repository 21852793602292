import styled from "styled-components"
import { useTheme } from "../../../utils/hooks/useTheme"
import colors from "../../../utils/style/colors"

const ErrorWrapper = styled.div`
    margin-top: 15px;
    width: 100%;
`

const ErrorContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
`

const Message = styled.div`
    font-size: 18px;
    font-weight: 500;
    color: red;
`

function ErrorRequest({ message }){

    const { theme } = useTheme();

    return (
        <ErrorWrapper>
            <ErrorContainer theme={theme}>
                <Message>{message}</Message>
            </ErrorContainer>
        </ErrorWrapper>
    )
};

export default ErrorRequest