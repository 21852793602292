import styled from "styled-components"
import { Link } from "react-router-dom";
import colors from "../../utils/style/colors";
import { useTheme } from "../../utils/hooks/useTheme";
import ClubCard from "../../components/Club/ClubCard";
import DiscoverSelector from "../../components/Selector/DiscoverSelector";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useAuth } from "../../utils/hooks/useAuth";
import UserCard from "../../components/User/UserCard";
import Update from "../../components/Utils/Update";
import VerticalHeader from "../../components/Header/VerticalHeader";
import { withNamespaces } from 'react-i18next';
import { Trans } from 'react-i18next';

const GETTOPCLUBS_URL = "/api/club/getClubs";
const GETUSERS_URL = "/api/user/getUsers";

const DiscoverContainer = styled.div`
  margin: 0;
  width: 100%;
  min-height: 100vh;

  @media (min-width: 200px) {
    max-width: 550px;
  }
  
  @media (min-width: 700px) {
    max-width: 550px;
  }

  @media (min-width: 1115px) {
    max-width: 1115px;
  }
`;

const DiscoverWrapper = styled.div`  
  display: flex;
  justify-content: center;
  margin-top: 65px;
  gap: 15px;
  flex-direction: row;
`;

const CardContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 200px;

  @media (min-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 1115px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const DiscoverTitle = styled.div`
    font-weight: 800;
    font-size: 4.5rem;
`;

const Extra = styled.span`
  color: ${colors.extra};
  font-size: 4.5rem;
  font-weight: 800;
`;

const DiscoverSubtitle = styled.div`
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  font-size: 1.5rem;
`;

const DiscoverHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  padding: 10px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;

  &:link,
  &:visited {
    text-decoration: none;
  }
`;

const Header = styled.div`
    display: flex;
    gap: 10px;
    flex-direction: column;
`

const NavigationHeader = styled.div`
    border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
    border-top: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    margin-bottom: 15px;
`

const HeaderContainer = styled.div`
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   border-radius: 10px;
`

const Contrast = styled.span`
  color:${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
  font-size: 1.5rem;
`

const ClassicButton = styled.button`
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;
  height: 40px;
  font-weight: 500;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled(ClassicButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color: ${colors.primary};

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

function Discover({ t }){

    const { theme } = useTheme();
    const { auth } = useAuth();

    const [discoverStatus, setDiscoverStatus] = useState("Clubs");

    const toggleDiscoverStatus = (status) => {
      setDiscoverStatus(status);
    };

    const [clubs, setClubs] = useState([]);
    const [users, setUsers] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    const [isLoading, setIsLoading] = useState(true);

    const fetchClubsData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(GETTOPCLUBS_URL, {});
        setClubs(response.data.lastClubs);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching clubs data:", err);
        setIsLoading(false);
      }
    };
  
    const fetchUsersData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosPrivate.get(GETUSERS_URL);
        setUsers(response.data.result);
        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching users data:", err);
        setIsLoading(false);
      }
    };

    useEffect(() => {
      if (auth.username && discoverStatus === "Clubs" && clubs.length === 0) {
        fetchClubsData();
      }
    }, [auth.username, discoverStatus, clubs]);
  
    useEffect(() => {
      if (auth.username && discoverStatus === "Users" && users.length === 0) {
        fetchUsersData();
      }
    }, [auth.username, discoverStatus, users]);

    useEffect(() => {
      document.title = `Havens - Discover`;
    }, []);

    return (
      <DiscoverWrapper>
        <div>
          <VerticalHeader />
        </div>
        <DiscoverContainer>
            <HeaderContainer>
              <DiscoverHeader theme={theme}>
                  <Header>
                      <DiscoverTitle>
                        <Trans i18nKey="discover.header.title">
                          Find your <Extra>Haven</Extra>
                        </Trans>
                      </DiscoverTitle>
                      <DiscoverSubtitle theme={theme}>
                        <Trans i18nKey="discover.header.subtitle">
                          Every <Contrast theme={theme}>stories</Contrast> starts from here ! You can also <Contrast theme={theme}>create</Contrast> your own clubs !
                        </Trans>
                      </DiscoverSubtitle>
                  </Header>
              </DiscoverHeader>
              <NavigationHeader theme={theme}>
                <DiscoverSelector DiscoverStatus={discoverStatus} toggleDiscoverStatus={toggleDiscoverStatus}/>
                <StyledLink to="../../create">
                  <ActiveButton theme={theme}>
                    {t('discover.buttons.create')}
                  </ActiveButton>
                </StyledLink>
              </NavigationHeader>
            </HeaderContainer>
            {discoverStatus === 'Trends' && (
              <Update message={'The top trends on Haven : Clubs, Users and Posts'}/>
            )}
            <CardContainer>
              {discoverStatus === 'Clubs' && !isLoading && clubs.map(({ club, avatar, banner, created_at, description, isMember, members_count, owner_id }, index) => (
                <ClubCard 
                    key={club} 
                    rank={index+1}
                    club={club}
                    avatar={avatar}
                    banner={banner}
                    created_at={created_at}
                    description={description}
                    isMember={isMember}
                    members_count={members_count}
                    owner_id={owner_id}
                />
              ))}
              {discoverStatus === 'Users' && !isLoading && users.map(({ username, avatar, description, followers_count }, index) => (
                <UserCard 
                    key={username} 
                    rank={index+1}
                    username={username}
                    avatar={avatar}
                    description={description}
                    followers_count={followers_count}
                />
              ))}
            </CardContainer>
        </DiscoverContainer>
      </DiscoverWrapper>
    )
};

export default withNamespaces()(Discover);