import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { LuImagePlus, LuLink } from "react-icons/lu";
import Avatar from "../../Avatar";
import { useTheme } from "../../../utils/hooks/useTheme";
import Icon from "../../Icon";
import { useAuth } from "../../../utils/hooks/useAuth";
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import { Link } from "react-router-dom";

const ComposeForm = styled.div`
  display: flex;
  border-radius: 10px;
  gap: 5px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  background-color: ${({theme}) => theme === 'light' ? colors.primaryBackground : colors.secondaryBackground};
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 100%;
`;

const PostComposerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 5px;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`

const PostTextarea = styled.textarea`
  padding: 5px;
  border: 1px solid
      ${({ theme }) =>
        theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  background: none;
  height: 30px;
  font-size: 0.8rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  resize: none;
`;

const AddImageIcon = styled(LuImagePlus)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const LinkIcon = styled(LuLink)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

function PostComposer({ text, color }) {
  const { theme } = useTheme();
  const { auth } = useAuth();

  if (!color) {
    color = colors.extra;
  }

  const [postText, setPostText] = useState("");

  const handlePostChange = (e) => {
    setPostText(e.target.value);
  };

  return (
    <StyledLink to="../submit">
      <ComposeForm theme={theme}>
        <Avatar size={42} url={auth.avatar ? auth.avatar : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)}/>
        <PostComposerContainer>
          <PostTextarea
            placeholder={text}
            value={postText}
            onChange={handlePostChange}
            rows={4}
            theme={theme}
          />
        </PostComposerContainer>
        <Icon theme={theme}>
          <LinkIcon />
        </Icon>
        <Icon theme={theme}>
          <AddImageIcon />
        </Icon>
      </ComposeForm>
    </StyledLink>
  );
}

export default PostComposer;
