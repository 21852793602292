import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import colors from "../../../utils/style/colors";
import axios from "../../../api/axios";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useTheme } from "../../../utils/hooks/useTheme";
import jwt_decode from "jwt-decode";
import { withNamespaces } from 'react-i18next';
import { Trans } from "react-i18next";

const LOGIN_URL = "/api/auth/login";

const LogInWrapper = styled.div`
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
`;

const LogInContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin-top: 65px;
  padding-top: 15px;
  padding-bottom: 50px;

  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormLogIn = styled.form`
  margin-right: 50px;
  margin-left: 50px;
`;

const InputBox = styled.div`
  height: 52px;
  margin: 25px 0;
  max-width: 500px;
`;

const Inputs = styled.input`
  height: 100%;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  font-weight: 400;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 6px;
  font-size: 15px;
  text-indent: 15px;
`;

const InputRegister = styled.input`
  margin-top: 10px;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorDark : colors.borderColorLight};
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 20px;
  border-radius: 0.25rem;
  white-space: nowrap;
`;

const ButtonRegisterNow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlreadyLogInMessage = styled.div`
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 20px;
  font-weight: 500;
`;

const Title = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 800;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-top: 5px;
  white-space: nowrap;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const Redirection = styled(Link)`
  font-size: 0.8rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
`;

const ErrorMessage = styled.div`
  color: red;
  text-align: center;
`;

const Extra = styled.span`
  font-size: 40px;
  color: ${colors.extra};
`;

const Policy = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.8rem;
`;

const Terms = styled.div`
  margin-left: 5px;
  font-size: 0.8rem;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText}; 
`

function LogIn({ t }) {
  const { theme } = useTheme();

  const navigate = useNavigate();

  const { setAuth, persist, setPersist } = useAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [username, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        },
      );
      const accessToken = response?.data?.accessToken;
      const decodedToken = jwt_decode(accessToken);
      const email = decodedToken.UserInfo.email;
      const description = decodedToken.UserInfo.description;
      const country = decodedToken.UserInfo.country;
      const color = decodedToken.UserInfo.color;
      const twitter = decodedToken.UserInfo.twitter;
      const github = decodedToken.UserInfo.github;
      const reddit = decodedToken.UserInfo.reddit;
      const discord = decodedToken.UserInfo.discord;
      const spotify = decodedToken.UserInfo.spotify;
      const youtube = decodedToken.UserInfo.youtube;
      const twitch = decodedToken.UserInfo.twitch;
      const linkedin = decodedToken.UserInfo.linkedin;
      const avatar = decodedToken.UserInfo.avatar;
      setAuth({ username, email, description, country, color, accessToken, twitter, github, reddit, discord, spotify, youtube, twitch, linkedin, avatar });
      setUsername("");
      setPassword("");
      navigate(`/home`);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setErrMsg("Missing username or password");
        } else if (err.response.status === 404) {
          setErrMsg("User not found or password incorrect");
        } else if (err.response.status === 500) {
          setErrMsg("Internal server error");
        } else setErrMsg("Login failed");
      } else {
        setErrMsg("Login Failed");
      }
    }
  };

  const togglePersist = () => {
    setPersist(prev => !prev);
  }

  useEffect(() => {
      localStorage.setItem("persist", persist);
  }, [persist])

  useEffect(() => {
    document.title = `Havens - Log In`;
  }, []);

  return (
    <LogInWrapper theme={theme}>
      <LogInContainer theme={theme}>
        <FormLogIn onSubmit={handleSubmit}>
          <Title>
            <Trans i18nKey="logIn.header.title">
              Welcome <Extra>back</Extra>
            </Trans>
          </Title>
          <Subtitle theme={theme}>{t('logIn.header.subtitle')}</Subtitle>

          <InputBox>
            <Inputs
              theme={theme}
              type="text"
              placeholder={t('logIn.inputs.username')}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </InputBox>
          <InputBox>
            <Inputs
              theme={theme}
              type="password"
              placeholder={t('logIn.inputs.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </InputBox>

          <ButtonRegisterNow>
            <InputRegister theme={theme} type="Submit" value={t('logIn.footer.button')} onChange={handleSubmit}/>
          </ButtonRegisterNow>
          <AlreadyLogInMessage theme={theme}>
            {t('logIn.footer.didntSign')}{" "}
            <Redirection to="/signup" theme={theme}>
              {t('logIn.footer.redirection')}
            </Redirection>
          </AlreadyLogInMessage>
          <Policy>
            <input 
              type="checkbox"
              id="persist"
              onChange={togglePersist}
              checked={persist}
            />
            <Terms>
              <Trans i18nKey="logIn.footer.trust">
                <Redirection theme={theme}>Trust</Redirection>
                this device
              </Trans>
            </Terms>
          </Policy>
        </FormLogIn>
        {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
      </LogInContainer>
    </LogInWrapper>
  );
}

export default withNamespaces()(LogIn);
