import { createGlobalStyle } from "styled-components";
import colors from "./colors";
import { useTheme } from "../hooks/useTheme";

const StyledGlobalStyle = createGlobalStyle`
    * {
      font-family: 'Inter';
      margin: 0;
      font-size: calc(15px + 0.390625vw);
    }

    body {
        background-color: ${({ isDarkMode }) =>
          isDarkMode ? colors.secondary : colors.primary};
        color: ${({ isDarkMode }) => (isDarkMode ? colors.primary : colors.secondary)};
    }
`;

function GlobalStyle() {
  
  const {theme} = useTheme();
  
  return <StyledGlobalStyle isDarkMode={theme === "dark"} />;
}

export default GlobalStyle;
