import styled from "styled-components";
import PostComposer from "../../components/Post/PostComposer";
import TimelineBody from "../../components/Timeline/TimelineBody";
import colors from "../../utils/style/colors";
import { useTheme } from "../../utils/hooks/useTheme";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useAuth } from "../../utils/hooks/useAuth";
import UserSuggestion from "../../components/User/UserSuggestion"
import { fictiveTimeline } from "../../components/Timeline/TimelineBody/fictiveTimeline";
import VerticalClubs from "../../components/Club/VerticalClubs";
import { withNamespaces } from "react-i18next";
import Error from "../../components/Utils/Error";

const GETALLPOSTS_URL = "/api/post/getPosts";

const HomeWrapper = styled.div`
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  justify-content: center;
  gap: 15px;
`

const HomeContainer = styled.div`
  min-height: 100vh;
  max-width: 550px;
`;

const Separator = styled.div`
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-top: 0;
  border-bottom: 0;
`;

const UserSuggestionContainer = styled.div`
  position: sticky;
  max-width: 300px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const VerticalClubsContainer = styled.div`
  position: sticky;
  display: flex;
  gap: 15px;
  flex-direction: column;
`

function FictiveHome({ t }) {
  const { theme } = useTheme();
  const { auth } = useAuth();

  useEffect(() => {
    document.title = `Havens - Fictive Timeline`;
  }, []);

  return (
    auth.accessToken ? (
        <Error />
    ) : (
        <HomeWrapper>
            {/* <VerticalClubsContainer>
                <VerticalClubs />
            </VerticalClubsContainer> */}
            <HomeContainer>
                <PostComposer text={t('fictiveHome.create.message')} />
                <Separator theme={theme} />
                <TimelineBody posts={fictiveTimeline} commentStatus={false} end={true} fictive={true}/> 
            </HomeContainer>
        </HomeWrapper>
    )
  );
}

export default withNamespaces()(FictiveHome);
