import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/index";
import Contact from "./pages/Auth/Contact";
import Error from "./components/Utils/Error";
import SignUp from "./pages/Auth/SignUp";
import LogIn from "./pages/Auth/LogIn";
import Welcome from "./pages/Welcome";
import Profile from "./pages/Profile/index.jsx";
import Comment from "./components/Comment/CommentSection";
import RequireAuth from "./components/Utils/RequireAuth/index.jsx";
import EditProfile from "./components/User/EditProfile/index.jsx";
import Premium from "./pages/Premium/index.jsx";
import Submit from "./pages/Submit/index.jsx";
import Club from "./pages/Club/index.jsx";
import Discover from "./pages/Discover/index.jsx";
import Create from "./pages/Create/index.jsx";
import EditClub from "./components/Club/EditClub/index.jsx";
import PersistLogin from "./components/Auth/PersistLogin/PersistLogin.jsx";
import FictiveHome from "./pages/FictiveHome/index.jsx";
import FollowsOverview from "./pages/FollowsOverview/index.jsx";
import LikesOverview from "./pages/LikesOverview/index.jsx";

function App() {

    return (
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LogIn />} />
            <Route path="/fictivehome" element={<FictiveHome />} />

            <Route element={<PersistLogin />}>

                <Route element={<RequireAuth />}>
                    <Route path="/contact" element={<Contact />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/home" element={<Home />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/:username/comments/:id" element={<Comment />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/profile/:mention" element={<Profile />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/club/:club" element={<Club />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/discover/clubs" element={<Discover />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/create" element={<Create />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/comments" element={<Comment />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/settings/account" element={<EditProfile />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/edit/club/:club" element={<EditClub />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/submit" element={<Submit />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/premium" element={<Premium/>} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/:username/overview" element={<FollowsOverview />} />
                </Route>

                <Route element={<RequireAuth />}>
                    <Route path="/:post_id/details" element={<LikesOverview />} />
                </Route>

            </Route>

            <Route path="*" element={<Error />} />
        </Routes>
    );
}

export default App;