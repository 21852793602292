import styled from "styled-components";
import { Link } from "react-router-dom";
import Avatar from "../../Avatar";
import { useState } from "react";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import PostActions from "../PostActions";
import TimeElapsedFormatter from "../../Utils/TimeElapsedFormatter";
import React from 'react';
import MentionText from "../../Utils/MentionText";

const PostContainer = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const CommentContainer = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 10px;
  display: flex;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-bottom: none;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  box-sizing: border-box;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const PostHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const PostContent = styled.div`
  padding-bottom: 15px;
  font-size: 0.8rem;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
  display: flex;
  font-size: 0.8rem;
`;

const StyledClub = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
  text-decoration: none;
  text-decoration: underline;
  font-size: 0.8rem;
`

const PostUser = styled.span`
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  font-size: 0.8rem;

  &:hover {
    cursor: pointer;
  }
`;

const PostName = styled.span`
  padding-right: 5px;
  font-weight: 600;
  font-size: 0.8rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const PostCreatedOn = styled.span`
  font-size: 0.8rem;
  flex-grow: 1;
  padding-left: 5px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`;

const PostText = styled.div`
  padding-left: 15px;
`;

const VerticalDivider = styled.div`
  width: 1px;
  background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
  margin-right: 8px;
  min-height: 20px;
  height: 100%;
  margin-top: -15px;
  margin-left: ${({ level }) => {
    const limitedLevel = Math.min(level, 5);
    const marginValue = 35 + limitedLevel * 30; 
    return `${marginValue}px`;
  }};
`;

const PostImage = styled.img`
  margin-bottom: 15px;
  max-width: 400px;
  max-height: 400px;
  height: auto;
  border-radius: 10px; 

  @media (min-width: 650px) {
    max-width: 450px;
    max-height: 450px;
  }

  @media (max-width: 500px) {
    max-width: 350px;
    max-height: 350px;
  }

  @media (max-width: 400px) {
    max-width: 250px;
    max-height: 250px;
  }
`;

const PostCategorie = styled.div`
  font-size: 0.8rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const PostWrapper = styled.div`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
`

const CommentWrapper = styled.div`
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-left: 15px;
  width: 100%;
`

function PostBody(props) {
  const [viewComment, setViewComment] = useState(false);

  const { theme } = useTheme();

  function toggleViewComment() {
    setViewComment((prevStatus) => !prevStatus);
  }

  const {
    username,
    avatar,
    created_at,
    post_id,
    parent_id,
    club,
    content,
    level,
    comment_count,
    repost_count,
    like_count,
    isLiked,
    image,
    onPostSubmit,
    onPostDelete,
  } = props;

  return level === 0 || !level ? (
    <>
      <PostWrapper>
        <PostContainer onClick={() => toggleViewComment()} theme={theme}>
          <PostCategorie>
            {club !== 'everyone' ? (
              <>/everyone/<StyledClub to={`../club/${club}`}>{club}</StyledClub></>
            ) : '/everyone'}
          </PostCategorie>
          <StyledLink theme={theme} to={`/${username}/comments/${post_id}`}>
            <StyledLink to={`/profile/${username}`} theme={theme}>
              <Avatar name={username} size={40} url={avatar}/>
            </StyledLink>
            <PostText>
              <PostHeader>
                <HeaderLeft>
                  <StyledLink to={`/profile/${username}`}>
                    <PostName theme={theme}>{username}</PostName>
                    <PostUser><MentionText text={'@' + username}/> -</PostUser>
                  </StyledLink>
                  <PostCreatedOn><TimeElapsedFormatter createdTimeString={created_at}/></PostCreatedOn>
                </HeaderLeft>
              </PostHeader>
              <PostContent><MentionText text={content} /></PostContent>
              {image && <PostImage src={image} alt={`${username}'s post image`} />}
            </PostText>
          </StyledLink>
        </PostContainer>
      <PostActions
          onPostSubmit={onPostSubmit}
          onPostDelete={onPostDelete}
          counters={{
          post_id: post_id,
          parent_id: parent_id,
          username: username,
          isLiked: isLiked,
          club: club,

          comment_count: comment_count,
          repost_count: repost_count,
          like_count: like_count,
        }}
        />
      </PostWrapper>
    </>
  ) : (
    <>
      <VerticalDivider level={level} />
      <CommentWrapper>
        <CommentContainer onClick={toggleViewComment} level={level} theme={theme}>
          <StyledLink theme={theme} to={`/${username}/comments/${post_id}`}>
            <StyledLink to={`/profile/${username}`} theme={theme}>
              <Avatar name={username} size={40} url={avatar}/>
            </StyledLink>
            <PostText>
              <PostHeader>
                <StyledLink to={`/profile/${username}`}>
                  <PostName theme={theme}>{username}</PostName>
                  <PostUser>@{username} -</PostUser>
                </StyledLink>
                <PostCreatedOn><TimeElapsedFormatter createdTimeString={created_at}/></PostCreatedOn>
              </PostHeader>
              <PostContent><MentionText text={content} /></PostContent>
              {image && <PostImage src={image} alt={`${username}'s post image`} />}
            </PostText>
          </StyledLink>
        </CommentContainer>
        <PostActions
            onPostSubmit={onPostSubmit}
            onPostDelete={onPostDelete}
            counters={{
            post_id: post_id,
            parent_id: parent_id,
            username: username,
            level: level,
            isLiked: isLiked,
            club: club,

            comment_count: comment_count,
            repost_count: repost_count,
            like_count: like_count,
          }}
        />
      </CommentWrapper>
    </>
  );
}

export default PostBody;
