import styled from "styled-components"
import { useParams } from "react-router-dom";
import { useTheme } from "../../utils/hooks/useTheme";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useEffect, useState } from "react";
import ClubProfile from "../../components/Club/ClubProfile";
import ClubSelector from "../../components/Selector/ClubSelector";
import TimelineBody from "../../components/Timeline/TimelineBody";
import EmptyTimeline from "../../components/Timeline/EmptyTimeline";
import ClubAbout from "../../components/Club/ClubAbout";
import UserCard from "../../components/User/UserCard";
import { useAuth } from "../../utils/hooks/useAuth";
import VerticalHeader from "../../components/Header/VerticalHeader";
import ErrorRequest from "../../components/Utils/ErrorRequest";

const ClubWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 65px;
    gap: 15px;
    flex-direction: row;
`

const ClubContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 815px;
    border-radius: 10px;
    width: 100%;
`

const TimelineWrapper = styled.div`
    width: 100%; 
`;

const AboutWrapper = styled.div`
    max-width: 250px;
    width: 100%; 

    @media (max-width: 750px) { 
        display: none;
    }
`

const BodyClub = styled.div`
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    overflow: hidden;   
`

const CardContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-content: center;
  margin: 0 auto;
  margin-top: 15px;
  width: 100%;

  @media (min-width: 1700x) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 650px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const Header = styled.div`
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
`

function Club(){

    const { club } = useParams();
    const { theme } = useTheme();
    const { auth } = useAuth();

    const GETCLUB_URL = `api/club/getClub/${club}`;
    const GETPOSTBYCLUB_URL = `api/post/getPostsByClub/${club}`;
    const GETMEMBERS_URL = `api/member/getMembers/${club}`;
    const JOINCLUB_URL = `api/club/joinClub/${club}`;
    const LEFTCLUB_URL = `api/club/leftClub/${club}`;

    const [clubStatus, setClubStatus] = useState("Top");

    const toggleClubStatus = (status) => {
        setClubStatus(status);
        setIsError(false);
        setError('');
    };

    const axiosPrivate = useAxiosPrivate();

    const [owner, setOwner] = useState();
    const [desc, setDesc] = useState();
    const [memberCount, setMemberCount] = useState();
    const [avatar, setAvatar] = useState();
    const [banner, setBanner] = useState();
    const [createdAt, setCreatedAt] = useState();
    const [isMember, setIsMember] = useState();
    const [isOwner, setIsOwner] = useState();
    const [isCreated, setIsCreated] = useState();
    const [clubNotFound, setClubNotFound] = useState(false);

    const [posts, setPosts] = useState([]);
    const [newPosts, setNewPosts] = useState([]);
    const [events, setEvents] = useState([]);
    const [mentions, setMentions] = useState([]);
    const [members, setMembers] = useState([]);

    const [reloadFlag, setReloadFlag] = useState(false);
    const [isError, setIsError] = useState(false);
    const [error, setError] = useState();

    const handlePostDelete = () => {
        setReloadFlag(prevFlag => !prevFlag);
    };

    function toggleJoinStatus() {
        
        if (auth.username && club !== 'everyone'){
            
            if (isMember === false) {
                try {
                    axiosPrivate.post(JOINCLUB_URL);

                    setMemberCount(memberCount + 1);
                    setClubStatus('Top');
                    setIsMember(true);

                } catch (err) {

                    setError(err.response);
                    setIsError(true);

                }
            } else if (isMember === true && isOwner !== true){
                try {
                    axiosPrivate.post(LEFTCLUB_URL);

                    setMemberCount(memberCount - 1);
                    setClubStatus('Top');
                    setIsMember(false);

                } catch (err) {

                    setError(err.response);
                    setIsError(true);

                }
            }

        } else {
            setIsError(true);
            setError("You can't leave the club everyone.")
        }
    }

    useEffect(() => {
        try {
            axiosPrivate
            .get(GETCLUB_URL)
            .then((response) => {
                setOwner(response.data.infoClub[0].username);
                setDesc(response.data.infoClub[0].description);
                setMemberCount(response.data.infoClub[0].members_count);
                setAvatar(response.data.infoClub[0].avatar);
                setBanner(response.data.infoClub[0].banner);
                setCreatedAt(response.data.infoClub[0].created_at);   
                setIsMember(response.data.infoClub[0].isMember === 1 ? true : false);
                setIsOwner(response.data.infoClub[0].isOwner);
                setIsCreated(response.data.infoClub[0].created_at);

                setClubNotFound(false);
            })
            .catch((err) => {
                setError(err.response);
                setIsError(true);

                // if (err.response && err.response.status === 404) {
                //     // Club not found, set the clubNotFound state
                //     setClubNotFound(true);
                // }

                setClubNotFound(true);
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
    }, [club]);

    useEffect(() => {
        try {
            axiosPrivate
            .get(GETPOSTBYCLUB_URL)
            .then((response) => {
                setPosts(response.data.lastPosts);
            })
            .catch((err) => {
                setError(err.response);
                setIsError(true);
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
    }, [clubStatus === 'Top']);

    useEffect(() => {
        try {
            axiosPrivate
            .get(GETMEMBERS_URL)
            .then((response) => {
                setMembers(response.data.members);
            })
            .catch((err) => {
                setError(err.response);
                setIsError(true);
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
    }, [clubStatus === 'Members']);

    useEffect(() => {
        document.title = `${'Havens - Club ' + club}`;
    }, [club]);

    return (
        <ClubWrapper>
            <div>
                <VerticalHeader />
            </div>
            <ClubContainer>
                <Header>
                    <ClubProfile 
                        bannerUrl={banner}
                        profileImageUrl={avatar}
                        club={club}
                        desc={desc}
                        memberCount={memberCount}
                        isCreated={isCreated}
                        isMember={isMember}
                        isOwner={isOwner}
                        toggleJoinStatus={toggleJoinStatus}
                        clubNotFound={clubNotFound}
                        toggleClubStatus={toggleClubStatus}
                    />
                    <ClubSelector clubStatus={clubStatus} toggleClubStatus={toggleClubStatus}/>
                </Header>
                <BodyClub>
                    {clubNotFound ? (
                        <ErrorRequest message={`Club ${club} not found`} />
                    ) : (
                        <>
                            {clubStatus === "Top" && (
                                <TimelineWrapper>
                                    {isError && (
                                        <ErrorRequest message={error}/>
                                    )}
                                    {posts.length > 0
                                        ? <TimelineBody posts={posts} onPostDelete={handlePostDelete}/>
                                        : <EmptyTimeline />
                                    }
                                </TimelineWrapper>
                            )}
                            {clubStatus === "New" && (
                                <TimelineWrapper>
                                    <EmptyTimeline />
                                </TimelineWrapper>
                            )}
                            {clubStatus === "Events" && (
                                <TimelineWrapper>
                                    <EmptyTimeline />
                                </TimelineWrapper>
                            )}
                            {clubStatus === "Mentions" && (
                                <TimelineWrapper>
                                    <EmptyTimeline />
                                </TimelineWrapper>
                            )}
                            {clubStatus === "Members" && (
                                <CardContainer>
                                    {isError && (
                                        <ErrorRequest message={error}/>
                                    )}
                                    {members.map(({ username, avatar, description, followers_count }, index) => (
                                        <UserCard 
                                            key={username} 
                                            rank={index+1}
                                            username={username}
                                            avatar={avatar}
                                            description={description}
                                            followers_count={followers_count}
                                        />
                                    ))}
                                </CardContainer>
                            )}
                            {clubStatus !== 'Members' && (
                                <AboutWrapper>
                                    <ClubAbout 
                                        bannerUrl={banner}
                                        profileImageUrl={avatar}
                                        club={club}
                                        desc={desc}
                                        memberCount={memberCount}
                                        isCreated={isCreated}
                                        isMember={isMember}
                                        isOwner={isOwner}
                                        owner={owner}
                                    />
                                </AboutWrapper>
                            )}
                        </>
                    )}
                </BodyClub>
            </ClubContainer>
        </ClubWrapper>
    )
};

export default Club
