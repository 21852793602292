const presentationTimelineEn = [
    {
        username: "User",
        post_id: 1,
        content: "I don't know this place, where i am ?",
        created_at: "2030-11-07T12:00:00",
        avatar: '',
        comment_count: 0,
        repost_count: 0,
        like_count: 0,
        isLiked: false,
        club: 'everyone',
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Sorry, but you're in my story now.",
      created_at: "2030-11-07T12:00:00",
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png',
      comment_count: 9,
      repost_count: 3,
      like_count: 4,
      isLiked: true,
      club: 'everyone',
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Welcome on Havens !",
      created_at: "2030-11-07T12:00:00",
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png',
      comment_count: 9,
      repost_count: 3,
      like_count: 4,
      isLiked: true,
      club: 'everyone',
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Discover the world of clubs using Havens!",
      created_at: "2030-07T12:00:00",
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png',
      comment_count: 5,
      repost_count: 2,
      like_count: 10,
      isLiked: true,
      club: 'everyone',
  },
  ];
  
  export { presentationTimelineEn };
  