import styled from "styled-components";
import colors from "../../utils/style/colors";
import { Link } from "react-router-dom";
import { useTheme } from "../../utils/hooks/useTheme";
import { useEffect } from "react";
import TimelineBody from "../../components/Timeline/TimelineBody"
import { presentationTimelineEn } from "./presentationTimelineEn";
import { presentationTimelineFr } from "./presentationTimelineFr";
import ClubCard from "../../components/Club/ClubCard";
import { withNamespaces } from 'react-i18next';
import { Trans } from 'react-i18next';

const WelcomeWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const WelcomeContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Title = styled.h1`
  font-size: 4.5rem;
  color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
`;

const Subtitle = styled.div`
  font-size: 1.5rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const Extra = styled.span`
  color: ${colors.extra};
  font-size: 4.5rem;
`;

const Contrast = styled.span`
  font-size: 1.5rem;
  color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
`

const WelcomeButtons = styled.div`
    box-sizing: content-box;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 15px;
`

const ArrowButton = styled.button`
  display: flex;
  padding: 12px 17px 12px 17px;
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  font-size: 20px;
  background-color: ${({theme}) => theme === 'light' ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
  color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
  justify-content: center; 
  align-items: center; 
  font-weight: 500;

  .arrow {
    display: flex;
    align-items: center;
    width: 10px;
    height: 10px;
    border-right: 2px solid ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    border-bottom: 2px solid ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    position: relative;
    transform: rotate(-45deg);
    margin: 0 6px;
    transition: all 0.3s ease;
    
    &::before {
      display: block;
      background-color: currentColor;
      width: 20px;
      transform-origin: bottom right;
      height: 2px;
      position: absolute;
      opacity: 0;
      bottom: calc(-2px / 2);
      transform: rotate(45deg);
      transition: all 0.3s ease;
      content: "";
      right: 0;
    }
  }
  
  &:hover {

    background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    
    .arrow {
      transform: rotate(-45deg) translate(4px, 4px);
      
      &::before {
        opacity: 1;
        width: 15px;
      }
    }
  }
`;

const ActiveArrowButton = styled(ArrowButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color:  ${colors.primary};

    .arrow {
      border-right: 2px solid ${colors.primary};
      border-bottom: 2px solid ${colors.primary};
    }

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

const StyledLink = styled(Link)`
    text-decoration: none;
`

const TimelineContainer = styled.div`
    max-width: 550px;
    margin: 0 auto;
`

const QANDAContainer = styled.div`

`;

const Section = styled.section`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const FAQItem = styled.div`
  margin-bottom: 12px;
`;

const Question = styled.div`
  font-weight: 600;
`

const Answer = styled.div`
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const FAQ = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const CardContainer = styled.div`
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-content: center;
  margin: 0 auto;

  @media (min-width: 700px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 1115px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const TextBoxContainer = styled.div`

`

function Welcome({ t, i18n }) {

  const { theme } = useTheme();
  const isEnglish = i18n.language === 'en';

  useEffect(() => {
    document.title = `Havens - Welcome home !`;
  }, []);

  return (
    <WelcomeWrapper>
      <WelcomeContainer>
        <Header>
          <Title theme={theme}>
            <Trans i18nKey="welcome.introduction.title">
              Your voice <Extra>matters. Share </Extra>it.
            </Trans>
          </Title>
          <Subtitle theme={theme}>
            <Trans i18nKey="welcome.introduction.subtitle">
              Build your own social media! Join our vibrant <Contrast theme={theme}>club-based community</Contrast> and connect with people who <Contrast theme={theme}>share your passions.</Contrast> Discover, discuss, and dive into the world of clubs.
            </Trans>
          </Subtitle>
          <WelcomeButtons>
            <StyledLink to="/fictivehome">
              <ArrowButton theme={theme}>
                {t('welcome.introduction.preview')}
                <span className="arrow" theme={theme}></span>
              </ArrowButton>
            </StyledLink>
            <StyledLink to="/signup">
              <ActiveArrowButton theme={theme}> 
                {t('welcome.introduction.signUp')}
                <span className="arrow" theme={theme}></span>
              </ActiveArrowButton>
            </StyledLink>
          </WelcomeButtons>
        </Header>
      </WelcomeContainer>
      <TimelineContainer>
        {isEnglish && (
          <TimelineBody posts={presentationTimelineEn} fictive={true}/>
        )}
        {!isEnglish && (
          <TimelineBody posts={presentationTimelineFr} fictive={true}/>
        )}
      </TimelineContainer>
      <TextBoxContainer>
        <Header>
          <Title theme={theme}>
            <Trans i18nKey="welcome.contribution.title">
              Contribute to the <Extra>project</Extra>
            </Trans>
          </Title>
          <Subtitle theme={theme}>
            <Trans i18nKey="welcome.contribution.subtitle">
              Havens is a <Contrast theme={theme}>new</Contrast> social media that is aim to grow. If you would like to put <Contrast theme={theme}>your contribution</Contrast> to the project, feel free to contact us !
            </Trans>
          </Subtitle>
          <WelcomeButtons>
            <StyledLink to="/contact">
              <ActiveArrowButton theme={theme}> 
                {t('welcome.contribution.button')}
                <span className="arrow" theme={theme}></span>
              </ActiveArrowButton>
            </StyledLink>
          </WelcomeButtons>
        </Header>
      </TextBoxContainer>
      <CardContainer>
        <ClubCard 
          club={'Havens'}
          avatar={'http://api.havens.fr/avatars/avatar_1710581998761SimpleLogo.png'}
          banner={'http://api.havens.fr/banners/banner_1710581998761Banner_havens.png'}
          created_at={Date.now()}
          description={'News and update about your favorite Social Media'}
          isMember={true}
          members_count={1342}
        />
        <ClubCard 
          club={'everyone'}
          avatar={'http://api.havens.fr/avatars/avatar_1710581977446SimpleLogo.png'}
          banner={'http://api.havens.fr/banners/banner_1710581977447Banner_everyone.png'}
          created_at={Date.now()}
          description={'Where everything starts'}
          isMember={true}
          members_count={2362}
        />
      </CardContainer>
      <QANDAContainer>
        <Section>
          <Title theme={theme}>
            <Trans i18nKey="welcome.faq.title">
              Frequently asked <Extra>questions</Extra>
            </Trans>
          </Title>

            <FAQ>
              <FAQItem>
                <Question>{t('welcome.faq.question1')}</Question>
                <Answer theme={theme}>
                  Lorem ipsum dolor sit amet consectetur, adipisicing elit. Sunt
                  autem numquam dolore molestias aperiam culpa alias veritatis
                  architecto eos, molestiae vitae ex eligendi libero eveniet
                  dolorem, doloremque rem aliquid perferendis.
                </Answer>
              </FAQItem>

              <FAQItem>
                <Question>{t('welcome.faq.question2')}</Question>
                <Answer theme={theme}>
                  Distinctio corporis, iure facere ducimus quos consectetur ipsa ut
                  magnam autem doloremque ex! Id, sequi. Voluptatum magnam sed fugit
                  iusto minus et suscipit? Minima sunt at nulla tenetur, numquam
                  unde quod modi magnam ab deserunt ipsam sint aliquid dolores
                  libero repellendus cupiditate mollitia quidem dolorem odit
                </Answer>
              </FAQItem>

              <FAQItem>
                <Question>{t('welcome.faq.question3')}</Question>
                <Answer theme={theme}>
                  Minima sunt at nulla tenetur, numquam unde quod modi magnam ab
                  deserunt ipsam sint aliquid dolores libero repellendus cupiditate
                  mollitia quidem dolorem.
                </Answer>
              </FAQItem>

              <FAQItem>
                <Question>{t('welcome.faq.question4')}</Question>
                <Answer theme={theme}>
                  Laudantium perferendis, est alias iure ut veniam suscipit dolorem
                  fugit. Et ipsam corporis earum ea ut quae cum non iusto blanditiis
                  ipsum dolor eius reiciendis, velit adipisci quas.
                </Answer>
              </FAQItem>

              <FAQItem>
                <Question>{t('welcome.faq.question5')}</Question>
                <Answer theme={theme}>
                  Numquam unde quod modi magnam ab deserunt ipsam sint aliquid
                  dolores libero repellendus cupiditate mollitia quidem dolorem odit
                </Answer>
              </FAQItem>

              <FAQItem>
                <Question>{t('welcome.faq.question6')}</Question>
                <Answer theme={theme}>
                  Eaque eos corrupti debitis tenetur repellat, beatae quibusdam
                  incidunt, fuga non iste dignissimos officiis nam officia obcaecati
                  commodi ratione qui nesciunt.
                </Answer>
              </FAQItem>
            </FAQ>
          </Section>
      </QANDAContainer>
    </WelcomeWrapper>
  );
}

export default withNamespaces()(Welcome);