import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { FiArrowLeft, FiSliders, FiHash } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";

const LeftArrowIcon = styled(FiArrowLeft)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const SlidersIcon = styled(FiSliders)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const HashtagIcon = styled(FiHash)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const VerticalWrapper = styled.div`
    position: sticky;
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 1200px) {
        display: none;
    }
`;

const VerticalContainer = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const BarContainer = styled.div`
    background-color: ${({theme}) => theme === 'light' ? colors.primary : colors.secondary};
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    position: relative;
    border-radius: 10px;
    top: 50%;
    height: 320px;
`;

const Bar = styled.div`
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: ${colors.extra};
    position: absolute;
    bottom: 0;
    width: 100%;
    height: ${({ percentage }) => percentage}%;
    box-sizing: border-box;
    transform-origin: bottom;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

const ProgressText = styled.div`
    color: #fff;
    padding: 5px;
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
`;

function VerticalHeader({ message, percentage }) {
    const { theme } = useTheme();
    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
    };

    percentage=95;

    return (
        <VerticalWrapper theme={theme}>
            <VerticalContainer>
                <Icon><LeftArrowIcon theme={theme} onClick={handleGoBack}/></Icon>
                {/* <Icon><SlidersIcon theme={theme}/></Icon>
                <Icon><HashtagIcon theme={theme}/></Icon>
                <BarContainer theme={theme}>
                    <Bar theme={theme} percentage={percentage}>
                        <ProgressText>{percentage}%</ProgressText>
                    </Bar>
                </BarContainer> */}
            </VerticalContainer>
        </VerticalWrapper>
    );
}

export default VerticalHeader;
