import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import useAxiosPrivate from '../../../utils/hooks/useAxiosPrivate';
import colors from '../../../utils/style/colors';
import { useTheme } from '../../../utils/hooks/useTheme';
import Avatar from '../../Avatar';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../utils/hooks/useAuth';
import { truncateString } from '../../../utils/textAnalyser/truncateString';
import { withNamespaces } from 'react-i18next';
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import MentionText from '../../Utils/MentionText';

const SuggestionWrapper = styled.div`
  width: 100%;
  max-width: 250px;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SuggestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme === 'light' ? colors.backgroundLight : colors.backgroundDark};
  border: 1px solid ${({ theme }) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px;
  width: 100%;
`;

const UserList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const UserItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px;
  /* border: 1px solid ${({ theme }) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px; */
`;

const UserAvatar = styled(Avatar)`
  margin-right: 5px;
`;

const UserInfo = styled.div`
  padding-left: 10px;
  white-space: nowrap;
`;

const Username = styled.span`
  font-size: 0.8rem;
  margin-bottom: 4px;
  font-weight: 500;

  &:hover{
    text-decoration: underline;
    cursor: pointer;
  }
`

const UserDescription = styled.p`
  font-size: 0.8rem;
  margin: 0;
  margin-top: 4px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`;

const Title = styled.div`
    font-size: 0.8rem;
    text-align: center;
    margin-bottom: 5px;
    font-weight: 600;
`

const StyledLink = styled(Link)`
    text-decoration: none;
    color:${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
`

function UserSuggestion({ t }) {
  const axiosPrivate = useAxiosPrivate();
  const { theme } = useTheme();
  const {auth} = useAuth();
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    try {
      axiosPrivate
        .get('/api/user/getUserSuggestion')
        .then((response) => {
          const users = response.data.result;

          if (users && users.length > 0) {
            setSuggestions(users);
          }
        })
        .catch((err) => {
          console.error("Erreur lors de la requête :", err);
        });
    } catch (err) {
      console.error("Erreur lors de la requête :", err);
    }
  }, []);

  return (
    <SuggestionWrapper>
      <SuggestionContainer theme={theme}>
        <Title>{t('userSuggestion.header.title')}</Title>
        <UserList>
          {suggestions.map((user) => (
            <UserItem key={user.id} theme={theme}>
              <UserAvatar url={user.avatar ? user.avatar : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)} size={40} />
              <UserInfo>
                <StyledLink to={`/profile/${user.username}`} theme={theme}>
                    <Username theme={theme}>{truncateString(user.username, 20)}</Username>
                </StyledLink>

                <UserDescription theme={theme}><MentionText text={truncateString(user.description, 20)}/></UserDescription>
              </UserInfo>
            </UserItem>
          ))}
        </UserList>
      </SuggestionContainer>
    </SuggestionWrapper>
  );
}

export default withNamespaces()(UserSuggestion);
