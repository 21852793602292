import "./Switch.css";

function Switch(props) {
  const { handleChange, isChecked } = props;

  return (
    <div className="container">
      <input
        type="checkbox"
        className="checkbox"
        id="checkbox"
        checked={isChecked}
        onChange={handleChange}
      />
      <label className="switch" htmlFor="checkbox">
        <span className="slider"></span>
      </label>
    </div>
  );
}

export default Switch;
