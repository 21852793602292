export function truncateString(str, maxLength) {
    if (str.length <= maxLength) {
      return str;
    } else {
      const lastSpaceIndex = str.lastIndexOf(' ', maxLength);
      
      const truncated = lastSpaceIndex === -1 ? str.slice(0, maxLength) : str.slice(0, lastSpaceIndex);
      
      return truncated + '...';
    }
  }