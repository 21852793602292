import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { withNamespaces } from "react-i18next";


const AboutContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 15px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const Content = styled.div`
    padding: 5px;
    margin: 5px;
    display: flex;
    flex-direction: column;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 1rem;
    text-align: center;
`

const SubTitle = styled.div`
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
    white-space: nowrap;
    text-align: center;
    font-size: 0.8rem;
`

const StatsContainer = styled.div`
    display: flex;
    gap: 10px;
    justify-content: space-around;
`

const Stat = styled.div`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 5px;
`

function ClubAbout({
    bannerUrl,
    profileImageUrl,
    club,
    desc,
    memberCount,
    isCreated,
    isMember,
    isOwner,
    owner,
    t
}){

    const {theme} = useTheme();

    return (
        <AboutContainer theme={theme}>
            <Content>
                <Title>{t('clubAbout.header.title')}</Title>
                <SubTitle>{t('clubAbout.header.subtitle')}</SubTitle>
            </Content>
        </AboutContainer>
    )
};

export default withNamespaces()(ClubAbout);