import styled from "styled-components"
import colors from "../../utils/style/colors";
import { useTheme } from "../../utils/hooks/useTheme";
import NavHeader from "../../components/Header/NavHeader";
import { useEffect } from "react";
import { withNamespaces } from "react-i18next";

const PremiumWrapper = styled.div`
  justify-content: center;
  margin: 0 auto;
  max-width: 550px;
  min-height: 100vh;
`;

const PremiumContainer = styled.div`
  width: 100%;
  margin-top: 65px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Content = styled.div`
   padding-top: 50px;
   padding-bottom: 50px;
   border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
   border-radius: 10px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   padding: 15px;
`

const Title = styled.div`
    font-weight: 800;
    font-size: 50px;
    text-align: center;
    color: ${colors.extra};
`

const Subtitle = styled.div`
    margin-top: 15px;
    text-align: center;
    font-size: 25px;
`

function Premium({ t }){

    const { theme } = useTheme();

    useEffect(() => {
        document.title = `Havens - Subscribe to Premium`;
    }, []);

    return (
        <PremiumWrapper>
            <PremiumContainer>
                <NavHeader mention={t('utils.header.message')}/>
                <Content theme={theme}>
                    <Title>{t('premium.header.title')}</Title>
                    <Subtitle>{t('premium.header.subtitle')}</Subtitle>
                </Content>
            </PremiumContainer>
        </PremiumWrapper>
    )
}

export default withNamespaces()(Premium);