import styled from "styled-components";
import { ThemeContext } from "../../../utils/context/ThemeProvider";
import { useContext, useEffect } from "react";
import colors from "../../../utils/style/colors";
import NavHeader from "../../../components/Header/NavHeader";
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

const ContactWrapper = styled.div`
  justify-content: center;
  margin: 0 auto;
  margin-top: 65px;
  max-width: 550px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`

const ContactContainer = styled.div`
  width: 100%;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Title = styled.div`
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
  white-space: nowrap;
  color:${colors.extra};
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const FormContact = styled.form`
  margin: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: auto;
`;

const SupportButton = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  background-color: #586AEA;
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  height: 40px;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
    opacity: 0.80;
  }
`;

function LogIn({ t }) {

  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.title = `Havens - Contact Team`;
  }, []);

  return (
    <ContactWrapper theme={theme}>
      <NavHeader mention={"Back"}/>
      <ContactContainer theme={theme}>
        <FormContact>
          <Title>{t('contact.header.title')}</Title>
          <Subtitle theme={theme}>{t('contact.header.subtitle')}</Subtitle>
          <iframe src="https://discord.com/widget?id=1137497286587453480&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </FormContact>
      </ContactContainer>
    </ContactWrapper>
  );
}

export default withNamespaces()(LogIn);
