import PropTypes from "prop-types";
import styled from "styled-components";
import { useAuth } from "../../utils/hooks/useAuth";
import { useState } from "react";
import { useEffect } from "react";
import avatarUnauthLight from "../../assets/avatarLight.png";
import avatarUnauthDark from "../../assets/avatarDark.png";
import { useTheme } from "../../utils/hooks/useTheme"

const AvatarContainer = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  vertical-align: bottom;
  border-radius: 10px;
`;

function Avatar({ name = "", size, url }) {

  const { auth } = useAuth();
  const { theme } = useTheme();

  const [avatarUrl, setAvatarUrl] = useState(url !== "" ? url : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark));

  useEffect(() => {
    if (url === "") {
      setAvatarUrl(theme === "light" ? avatarUnauthLight : avatarUnauthDark);
    } else {
      setAvatarUrl(url);
    }

  }, [theme, name, auth.username, url]);

  return (
    <AvatarContainer
      theme={theme}
      size={size}
      src={avatarUrl}
      alt={`Avatar de ${name}`}
    />
  );
}

Avatar.propTypes = {
  name: PropTypes.string,
};

export default Avatar;
