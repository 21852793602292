import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { getRandomQuote } from "../../../utils/textAnalyser/randomQuote";
import React from "react";

const EmptyTimelineContainer = styled.div`
  min-height: 70vh;
  width: 100%;
  margin-top: 15px;
`;

const EndMessage = styled.div`
  padding-left: 10%;
  padding-right: 10%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
`;

const EmptyTimeline = React.memo(() => {
  const { theme } = useTheme();
  const initialQuote = getRandomQuote();

  return (
    <EmptyTimelineContainer>
      <EndMessage theme={theme}>{initialQuote}</EndMessage>
    </EmptyTimelineContainer>
  );
});

export default EmptyTimeline;
