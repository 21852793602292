import UserProfile from "../../components/User/UserProfile"
import styled from "styled-components"
import { useAuth } from "../../utils/hooks/useAuth"
import { useTheme } from "../../utils/hooks/useTheme"
import { useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react"
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate"
import TimelineBody from "../../components/Timeline/TimelineBody"
import CategorieSelector from "../../components/Selector/CategorieSelector"
import EmptyTimeline from "../../components/Timeline/EmptyTimeline";
import NavHeader from "../../components/Header/NavHeader";
import ClubCard from "../../components/Club/ClubCard";
import { withNamespaces } from "react-i18next";

const ProfileWrapper = styled.div`
    min-height: 100vh;
    margin-top: 65px;
    display: flex;
    justify-content: center; 
`

const ProfileContainer = styled.div`
    display: flex;
    gap: 15px;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`

const TimelineContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 550px;
`

const CardContainer = styled.div`
    display: flex;
    gap: 15px;
    margin-top: 15px;
    flex-direction: column;
`

function Profile({ t }){

    const { auth, setAuth } = useAuth();
    const { theme } = useTheme();
    const { mention } = useParams();

    const [profilStatus, setProfilStatus] = useState("Posts");
    const [reloadFlag, setReloadFlag] = useState(false);

    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const [likes, setLikes] = useState([]);
    const [clubsJoined, setClubsJoined] = useState([]);

    const [desc, setDesc] = useState(
        auth.username === mention ? auth.description : "",
    );
    const [country, setCountry] = useState(
        auth.username === mention ? auth.country : "",
    );
    const [color, setColor] = useState(auth.color !== "" ? auth.color : "#4caf50",
    );
    const [twitter, setTwitter] = useState(
        auth.username === mention ? auth.twitter : "",
    );
    const [github, setGithub] = useState(
        auth.username === mention ? auth.github : "",
    );
    const [reddit, setReddit] = useState(
        auth.username === mention ? auth.reddit : "",
    );
    const [discord, setDiscord] = useState(
        auth.username === mention ? auth.discord : "",
    );
    const [spotify, setSpotify] = useState(
        auth.username === mention ? auth.spotify : "",
    );
    const [youtube, setYoutube] = useState(
        auth.username === mention ? auth.youtube : "",
    );
    const [twitch, setTwitch] = useState(
        auth.username === mention ? auth.twitch : "",
    );
    const [linkedin, setLinkedin] = useState(
        auth.username === mention ? auth.linkedin : "",
    );
    const [followCount, setFollowCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);

    const [info, setInfo] = useState(false);
    const [follow, setFollow] = useState(false);

    const [avatar, setAvatar] = useState(
    "https://institutcommotions.com/wp-content/uploads/2018/05/blank-profile-picture-973460_960_720-1.png",
    );

    const axiosPrivate = useAxiosPrivate();

    const GETALLCOMMENTS_URL = `api/post/getCommentsByUsername/${mention}`;
    const GETALLPOSTS_URL = `api/post/getPosts/${mention}`;
    const GETLIKEDPOSTS_URL = `api/post/getPostsLiked/${mention}`;
    const GETUSER_URL = `api/user/getUser/${mention}`;
    const FOLLOWUSER_URL = 'api/follow/followUser';
    const UNFOLLOW_URL = 'api/follow/unfollow';
    const GETCLUBSJOINED_URL = `api/club/getClubsJoined/${mention}`;

    const toggleProfilStatus = (status) => {
        setProfilStatus(status);
    };

    function toggleInfo(){
        setInfo((prevStatus) => !prevStatus);
    }

    function toggleFollow() {
        if (follow === false) {

        if (auth.username && auth.username !== mention) {
            try {

            axiosPrivate.post(FOLLOWUSER_URL, {
                followingUsername: mention,
            });
            setFollow(true);
            setFollowCount(followCount + 1);
            } catch (err) {
                console.error("Erreur lors de la requête :", err);
            }
        }
        } else {

        if (auth.username && auth.username !== mention) {
            try {

            axiosPrivate.post(UNFOLLOW_URL, {
                followingUsername: mention,
            });
            setFollow(false);
            setFollowCount(followCount - 1);
            } catch (err) {
                console.error("Erreur lors de la requête :", err);
            }
        }
        }
    }

    useEffect(() => {
        try {
            axiosPrivate
            .get(GETUSER_URL)
            .then((response) => {
                setDesc(response.data.infoUser[0].description);
                setCountry(response.data.infoUser[0].country);
                setTwitter(response.data.infoUser[0].twitter);
                setGithub(response.data.infoUser[0].github);
                setReddit(response.data.infoUser[0].reddit);
                setDiscord(response.data.infoUser[0].discord);
                setSpotify(response.data.infoUser[0].spotify);
                setYoutube(response.data.infoUser[0].youtube);
                setTwitch(response.data.infoUser[0].twitch);
                setLinkedin(response.data.infoUser[0].linkedin);
                setFollow(response.data.infoUser[0].isFollowing);
                setFollowCount(response.data.infoUser[0].followers_count);
                setFollowingCount(response.data.infoUser[0].following_count);
                if (response.data.infoUser[0].avatar !== ""){
                    setAvatar(response.data.infoUser[0].avatar);
                } else {
                    setAvatar("");
                }
                if (response.data.infoUser[0] && auth.username === mention){
                    const updatedAuth = { ...auth };
                    updatedAuth.avatar = response.data.infoUser[0].avatar;
                    setAuth(updatedAuth);
                }
            
            })
            .catch((err) => {
                console.error("Erreur lors de la requête :", err);

                setDesc('Nothing here.');
                setCountry('?');
                setTwitter('');
                setGithub('');
                setReddit('');
                setDiscord('');
                setSpotify('');
                setYoutube('');
                setTwitch('');
                setLinkedin('');
                setFollow(0);
                setFollowCount(0);
                setFollowingCount(0);
                setAvatar('');

                if (err.response) {
                    console.log(err.response);
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
    }, [mention]);

    useEffect(() => {
        if (auth.accessToken) {
        try {
            axiosPrivate
            .get(GETALLPOSTS_URL)
            .then((response) => {
                setPosts(response.data.lastPosts);
            })
            .catch((err) => {
                console.error("Erreur lors de la requête :", err);

                if (err.response) {
                    console.log(err.response);
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
        }
    }, [profilStatus === "Posts", mention, reloadFlag && profilStatus === 'Posts']);

    useEffect(() => {
        if (auth.accessToken) {
        try {
            axiosPrivate
            .get(GETALLCOMMENTS_URL)
            .then((response) => {
                setComments(response.data.comments);
            })
            .catch((err) => {
                console.error("Erreur lors de la requête :", err);

                if (err.response) {
                    console.log(err.response);
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
        }
    }, [profilStatus === "Replies", mention, reloadFlag && profilStatus === 'Replies']);

    useEffect(() => {
        if (auth.username) {
        try {
            axiosPrivate
            .get(GETLIKEDPOSTS_URL)
            .then((response) => {
                setLikes(response.data.likedPosts);
            })
            .catch((err) => {
                // Gérez les erreurs ici
                console.error("Erreur lors de la requête :", err);

                if (err.response) {
                console.log(err.response);
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                } else {
                console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
        }
    }, [profilStatus === "Likes", mention, reloadFlag && profilStatus === 'Likes']);

    useEffect(() => {
        if (auth.username) {
        try {
            axiosPrivate
            .get(GETCLUBSJOINED_URL)
            .then((response) => {
                const clubsWithData = response.data.clubs.map(club => ({
                    ...club,
                    isMember: 1 
                }));
                setClubsJoined(clubsWithData);
            })
            .catch((err) => {
                // Gérez les erreurs ici
                console.error("Erreur lors de la requête :", err);

                if (err.response) {
                    console.log(err.response);
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
        }
    }, [profilStatus === "Clubs", mention, reloadFlag && profilStatus === 'Clubs']);

    const userInfo = {
        auth,
        mention,
        avatar,
        desc,
        country,
        color,
        twitter,
        github,
        reddit,
        discord,
        spotify,
        youtube,
        twitch,
        linkedin,
        followCount,
        followingCount,
        toggleInfo,
        follow,
        toggleFollow,
        info
    };

    const handlePostDelete = () => {
        setReloadFlag(prevFlag => !prevFlag);
    };

    useEffect(() => {
        document.title = `Havens - Profile ${mention}`;
    }, [mention]);

    return (
        <ProfileWrapper>
            <ProfileContainer>
                <div>
                    <NavHeader mention={t('utils.header.message')}/>
                    <UserProfile userData={userInfo} />
                </div>
                <TimelineContainer>
                    <CategorieSelector profilStatus={profilStatus} toggleProfilStatus={toggleProfilStatus}/>
                    {/* <PostComposer /> */}
                    {profilStatus === "Posts" && (
                        posts.length > 0
                        ? <TimelineBody posts={posts} onPostDelete={handlePostDelete}/>
                        : <EmptyTimeline />
                    )}
                    {profilStatus === "Replies" && (
                        comments.length > 0
                        ? <TimelineBody posts={comments} onPostDelete={handlePostDelete}/>
                        : <EmptyTimeline />
                    )}
                    {profilStatus === "Likes" && (
                        likes.length > 0
                        ? <TimelineBody posts={likes} onPostDelete={handlePostDelete}/>
                        : <EmptyTimeline />
                    )}
                    {profilStatus === 'Clubs' && (
                        <CardContainer>
                            {clubsJoined.map(({ club, avatar, banner, created_at, description, isOwner, members_count, isMember }, index) => (
                                <ClubCard 
                                    key={club} 
                                    rank={index+1}
                                    club={club}
                                    avatar={avatar}
                                    banner={banner}
                                    created_at={created_at}
                                    description={description}
                                    isOwner={isOwner}
                                    members_count={members_count}
                                    isMember={isMember}
                                />
                            ))}
                        </CardContainer>
                    )}
                </TimelineContainer>
            </ProfileContainer>
        </ProfileWrapper>
    )
}

export default withNamespaces()(Profile);