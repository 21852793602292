import { useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../../utils/hooks/useAuth";

const RequireAuth = () => {
  const { auth } = useAuth();
  const location = useLocation();

  return (
      auth?.accessToken
        ? <Outlet />
        : <Navigate to="/login" state={{ from: location}} replace />
  )
};

export default RequireAuth;
