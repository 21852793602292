import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../../../utils/style/colors";

const MentionsContainer = styled.div`
  font-size: 0.8rem;
  display: inline-block;
`;

const MentionLink = styled(Link)`
  color: ${colors.extra};
  font-weight: 600;
  font-size: 0.8rem;
`;

const charList = [
  "!",
  '"',
  "#",
  "$",
  "%",
  "&",
  "'",
  "(",
  ")",
  "*",
  "+",
  ",",
  "-",
  ".",
  "/",
  ":",
  ";",
  "<",
  "=",
  ">",
  "?",
  "@",
  "[",
  "\\",
  "]",
  "^",
  "_",
  "`",
  "{",
  "|",
  "}",
  "~",
];

const removeLastIfMatch = (str, charList) => {
  if (typeof str !== "string" || str.length === 0) {
    return str;
  }

  const lastChar = str.charAt(str.length - 1);

  if (charList.includes(lastChar)) {
    return [str.slice(0, -1), lastChar];
  }

  return [str, ""];
};

const MentionText = ({ text, mentionGiven }) => {
  const words = text.split(" ");
  const result = [];

  words.forEach((word, index) => {
    if (word.startsWith("@") && word.length > 1) {
      const [mention, lastChar] = removeLastIfMatch(word, charList);
      result.push(
        <React.Fragment key={index}>
          {index > 0 && " "} 
          <MentionLink to={`/profile/${mentionGiven ? mentionGiven : mention.slice(1)}`}>
            {mention}
            {lastChar}
          </MentionLink>
        </React.Fragment>
      );
    } else if (word.startsWith("#") && word.length > 1) {
      const [hashtag, lastChar] = removeLastIfMatch(word, charList);
      result.push(
        <React.Fragment key={index}>
          {index > 0 && " "}
          <MentionLink to={`/club/${mentionGiven ? mentionGiven : hashtag.slice(1)}`}>
            {hashtag}
            {lastChar}
          </MentionLink>
        </React.Fragment>
      );
    } else if (word.startsWith("https://")) {
      const url = word;
      const [lastChar] = removeLastIfMatch(word, charList);
      result.push(
        <React.Fragment key={index}>
          {index > 0 && " "}
          <MentionLink 
            to={url} 
            target="_blank" 
            rel="noopener noreferrer" 
            onClick={(e) => {
              e.preventDefault();
              window.open(url, "_blank");
            }}>
            {url}
            {lastChar}
          </MentionLink>
        </React.Fragment>
      );
    } else {
      if (index > 0) {
        result.push(" ");
      }
      result.push(word);
    }
  });

  return <MentionsContainer>{result}</MentionsContainer>;
};

export default MentionText;
