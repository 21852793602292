import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { useTheme } from "../../../utils/hooks/useTheme"
import { useState } from "react";
import { withNamespaces } from "react-i18next";

const CategorieSelectorContainer = styled.div`
    width: 100%;
`

const CategorieBanner = styled.div`
  padding-left: 30px;
  display: flex;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  justify-content: flex-start;
  border-top: none;
  border-top-right-radius: none;
  border-top-left-radius: none;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
`;

const Categorie = styled.div`
  text-align: center;
  padding: 8px; 
  font-size: 0.8rem;
  cursor: pointer;
  color: ${({ active, theme }) => active !== '' ? (theme === 'light' ? colors.secondary : colors.primary) : (theme === 'light' ? colors.primaryText : colors.secondaryText)};
  text-decoration: ${({active}) => active ? 'underline' : 'none'};
  text-decoration-color: ${colors.greyContrast};
`;

function ClubSelector({clubStatus, toggleClubStatus, t}){

    const {theme} = useTheme();
 
    return (
        <CategorieSelectorContainer>
            <CategorieBanner theme={theme}>
                <Categorie 
                    theme={theme} 
                    onClick={() => toggleClubStatus("Top") }
                    active={clubStatus === "Top" ? "active" : ""}
                >
                    {t('club.selector.top')}
                </Categorie>
                <Categorie
                    theme={theme}
                    onClick={() => toggleClubStatus("New")}
                    active={clubStatus === "New" ? "active" : ""}
                >
                    {t('club.selector.new')}
                </Categorie>
                <Categorie
                    theme={theme}
                    onClick={() => toggleClubStatus("Events")}
                    active={clubStatus === "Events" ? "active" : ""}
                >
                    {t('club.selector.events')}
                </Categorie>
                <Categorie 
                    theme={theme} 
                    onClick={() => toggleClubStatus("Mentions")}
                    active={clubStatus === "Mentions" ? "active" : ""}
                >
                    {t('club.selector.mentions')}
                </Categorie>
                <Categorie 
                    theme={theme} 
                    onClick={() => toggleClubStatus("Members")}
                    active={clubStatus === "Members" ? "active" : ""}
                >
                    {t('club.selector.members')}
                </Categorie>
            </CategorieBanner>
        </CategorieSelectorContainer>
    )
};

export default withNamespaces()(ClubSelector);