import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import styled from "styled-components";

const UpdateContainer = styled.div`
    margin-top: 15px;
    width: 100%;
`

const UpdateMessage = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  text-align: center;
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
`

const Title = styled.div`
    font-weight: 500;
    font-size: 24px;
`

const Subtitle = styled.div`
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
    font-size: 20px;
`

function Update({ message }){

    const { theme } = useTheme();

    return (
        <UpdateContainer>
            <UpdateMessage theme={theme}>
                <Title>Coming soon</Title>
                <Subtitle theme={theme}>{message}</Subtitle>
            </UpdateMessage>
        </UpdateContainer>
    )
};

export default Update;