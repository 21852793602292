import styled from "styled-components";
import { useTheme } from "../../utils/hooks/useTheme";
import colors from "../../utils/style/colors";
import { Link } from "react-router-dom";

const PageWrapper = styled.div`
    margin-top: 150px;
    display: flex;
    flex-direction: column;
`

const FooterWrapper = styled.div`
    margin-top: auto;
    border-top: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
`

const FooterContainer = styled.footer`
    max-width: 1200px;
    margin: 0 auto;
`

const Content = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 15px;
`

const Title = styled.div`
    font-weight: 600;
    font-size: 1.5rem;
    color: ${colors.extra};
`

const Category = styled.div`
    font-size: 1rem;
`

const Categories = styled.div`
    display: flex;
    gap: 15px;
`

const ClassicButton = styled.button`
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;
  height: 40px;
  font-weight: 500;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled(ClassicButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color: ${colors.primary};

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

const StyledLink = styled(Link)`
    color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    text-decoration: none;
`

const CopyRight = styled.div`
    font-size: 0.8rem;
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

function Footer(){

    const {theme} = useTheme();

    return(
        <PageWrapper theme={theme}>
            <FooterWrapper theme={theme}>
                <FooterContainer>
                    <Content>
                        <Title>Havens.fr</Title>
                        <Categories>
                            <StyledLink to={"/contact"} theme={theme}>
                                <Category theme={theme}>Contact</Category>
                            </StyledLink>
                            <StyledLink to={"/contentpolicy"} theme={theme}>
                                <Category>Content-Policy</Category>
                            </StyledLink>
                            <StyledLink to={"/privacypolicy"} theme={theme}>
                                <Category>Privacy-Policy</Category>
                            </StyledLink>
                        </Categories>
                        <StyledLink to={"/contact"}>
                            <ActiveButton theme={theme}>Join Us</ActiveButton>
                        </StyledLink>
                        <CopyRight theme={theme}>Havens, Inc. © 2024. All rights reserved.</CopyRight>
                    </Content>
                </FooterContainer>
            </FooterWrapper>
        </PageWrapper>
    )
};

export default Footer;