import axios from "../../api/axios";
import { useAuth } from "./useAuth";
import jwt_decode from 'jwt-decode';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/api/auth/refresh", {
      withCredentials: true,
    });

    const decodedToken = jwt_decode(response.data.accessToken);

    setAuth(prev => {

      return {
          ...prev,
          accessToken: response.data.accessToken,
          username: decodedToken.UserInfo.username,
          email: decodedToken.UserInfo.email,
          description: decodedToken.UserInfo.description,
          country: decodedToken.UserInfo.country,
          followers_count: decodedToken.UserInfo.followers_count,
          following_count: decodedToken.UserInfo.following_count,
          avatar: decodedToken.UserInfo.avatar,
          twitter: decodedToken.UserInfo.twitter,
          github: decodedToken.UserInfo.github,
          reddit: decodedToken.UserInfo.reddit,
          discord: decodedToken.UserInfo.discord,
          spotify: decodedToken.UserInfo.spotify,
          youtube: decodedToken.UserInfo.youtube,
          twitch: decodedToken.UserInfo.twitch,
          linkedin: decodedToken.UserInfo.linkedin
      }
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;