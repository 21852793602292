import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { useTheme } from "../../../utils/hooks/useTheme"
import { useState } from "react";
import { withNamespaces } from 'react-i18next';

const CategorieSelectorContainer = styled.div`
    max-width: 550px;

    @media (min-width: 1200px) {
        width: 550px;
    }
`

const CategorieBanner = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  background-color: ${({theme}) => theme === 'light' ? colors.primaryBackground : colors.secondaryBackground};
  flex: 1;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 50px;
`;

const Categorie = styled.div`
  flex-grow: 1;
  text-align: center;
  padding: 10px; 
  font-size: 0.8rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  cursor: pointer;
  color: ${({ active, theme }) => active !== '' ? (theme === 'light' ? colors.secondary : colors.primary) : (theme === 'light' ? colors.primaryText : colors.secondaryText)};
  text-decoration: ${({active}) => active ? 'underline' : 'none'};
  text-decoration-color: ${colors.greyContrast};
`;

function FollowsSelector({followsStatue, toggleFollowsStatue, t}){

    const {theme} = useTheme();
 
    return (
        <CategorieSelectorContainer>
            <CategorieBanner theme={theme}>
                <Categorie 
                    theme={theme} 
                    onClick={() => toggleFollowsStatue("following") }
                    active={followsStatue === "following" ? "active" : ""}
                >
                    {t('profile.header.following')}
                </Categorie>
                <Categorie
                    theme={theme}
                    onClick={() => toggleFollowsStatue("followers")}
                    active={followsStatue === "followers" ? "active" : ""}
                >
                    {t('profile.header.followers')}
                </Categorie>
            </CategorieBanner>
        </CategorieSelectorContainer>
    )
};

export default withNamespaces()(FollowsSelector);