import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import colors from "../../../utils/style/colors";
import axios from "../../../api/axios";
import { useTheme } from "../../../utils/hooks/useTheme";
import { useRef, useState, useEffect } from "react";
import { useAuth } from "../../../utils/hooks/useAuth";
import jwt_decode from "jwt-decode";
import { withNamespaces } from 'react-i18next';
import { Trans } from "react-i18next";

const USER_REGEX = /^[A-z][A-z0-9-_]{3,15}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%*]).{8,24}$/;
const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

const REGISTER_URL = "api/auth/signup";
const LOGIN_URL = "/api/auth/login";

const SignUpWrapper = styled.div`
  justify-content: center;
  margin: 0 auto;
  max-width: 600px;
  min-height: 100vh;
`;

const SignUpContainer = styled.div`
  max-width: 600px;
  width: 100%;
  margin-top: 65px;
  padding-top: 15px;
  padding-bottom: 50px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FormSignUp = styled.form`
  margin-right: 50px;
  margin-left: 50px;
`;

const InputBox = styled.div`
  margin: 25px 0;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

const Inputs = styled.input`
  height: 52px;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  font-weight: 500;
  font-size: 15px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  text-indent: 15px;
`;

const InputRegister = styled.input`
  margin-top: 20px;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorDark : colors.borderColorLight};
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 20px;
  border-radius: 0.25rem;
  white-space: nowrap;
`;

const ButtonRegisterNow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AlreadySignUpMessage = styled.div`
  color: #8f8f8f;
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 20px;
  font-weight: 500;
`;

const Title = styled.div`
  text-align: center;
  font-size: 40px;
  font-weight: 800;
`;

const Subtitle = styled.div`
  text-align: center;
  font-size: 1rem;
  margin-top: 5px;
  white-space: nowrap;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const Redirection = styled(Link)`
  font-size: 0.8rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
`;

const Policy = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 0.8rem;
`;

const ErrorMessage = styled.div`
  margin-top: 20px;
  color: red;
  text-align: center;
`;

const Terms = styled.div`
  font-size: 0.8rem;
  margin-left: 5px;
  font-weight: 500;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText}; 
`

const Extra = styled.span`
  font-size: 40px;
  color: ${colors.extra};
`;

function SignUp({ t }) {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const userRef = useRef();
  const errRef = useRef();

  const [username, setUsername] = useState("");
  const [validUsername, setValidUsername] = useState(false);

  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);

  const [matchPassword, setMatchPassword] = useState("");
  const [validMatchPassword, setValidMatchPassword] = useState(false);

  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setValidUsername(USER_REGEX.test(username));
  }, [username]);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    setValidPassword(PWD_REGEX.test(password));
    setValidMatchPassword(password === matchPassword);
  }, [password, matchPassword]);

  useEffect(() => {
    setErrMsg("");
  }, [username, password, email, matchPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const v1 = USER_REGEX.test(username);
    const v2 = PWD_REGEX.test(password);
    const v3 = EMAIL_REGEX.test(email);

    if (!v1 || !v2 || !v3) {
      setErrMsg("Invalid Entry");
      return;
    }
    try {
      const response = await axios.post(
        REGISTER_URL,
        JSON.stringify({ username, email, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        },
      );
      // TODO: remove console.logs before deployment
      console.log(JSON.stringify(response?.data));
      //console.log(JSON.stringify(response))
      setSuccess(true);
      //clear state and controlled inputs
      setErrMsg("");
      setEmail("");
      setMatchPassword("");

      loginAfterRegistration(username, password);
    } catch (err) {
      // Erreur lors de la requête
      console.error("Erreur lors de la requête :", err);

      if (err.response) {
        // Erreur de réponse du serveur
        console.error("Erreur de réponse du serveur :", err.response.data);
        if (err.response.status === 409) {
          setErrMsg("Username Taken");
        } else {
          setErrMsg("Registration Failed");
        }
      } else {
        // Erreur générique (peut se produire si le serveur est inaccessible)
        console.error("Erreur générique :", err.message);
        setErrMsg("Registration Failed");
      }
    }
  };

  const loginAfterRegistration = async (username, password) => {
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ username, password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        },
      );

      const accessToken = response?.data?.accessToken;
      const decodedToken = jwt_decode(accessToken);
      const email = decodedToken.UserInfo.email;
      const description = decodedToken.UserInfo.description;
      const country = decodedToken.UserInfo.country;
      const color = decodedToken.UserInfo.color;
      const twitter = decodedToken.UserInfo.twitter;
      const github = decodedToken.UserInfo.github;
      const reddit = decodedToken.UserInfo.reddit;
      const discord = decodedToken.UserInfo.discord;
      const spotify = decodedToken.UserInfo.spotify;
      const youtube = decodedToken.UserInfo.youtube;
      const twitch = decodedToken.UserInfo.twitch;
      const linkedin = decodedToken.UserInfo.linkedin;
      const avatar = decodedToken.UserInfo.avatar;
      setAuth({ username, email, description, country, color, accessToken, twitter, github, reddit, discord, spotify, youtube, twitch, linkedin, avatar });

      setUsername("");
      setPassword("");

      navigate(`/Profile/${username}`);
    } catch (err) {
      if (err.response) {
        if (err.response.status === 400) {
          setErrMsg("Missing username, email or password");
        } else if (err.response.status === 404) {
          setErrMsg("A user with this name already exists");
        } else if (err.response.status === 500) {
          setErrMsg("Internal server error");
        }
      } else {
        setErrMsg("Registration failed");
      }
    }
  };

  useEffect(() => {
    document.title = `Havens - Sign Up`;
  }, []);

  return (
    <SignUpWrapper theme={theme}>
      <SignUpContainer theme={theme}>
        <FormSignUp onSubmit={handleSubmit}>
          <Title>
            <Trans i18nKey="signUp.header.title">
              <Extra>Create</Extra> you're Account
            </Trans>
          </Title>
          <Subtitle>{t('signUp.header.subtitle')}</Subtitle>

          <InputBox>
            <Inputs
              theme={theme}
              id="username"
              ref={userRef}
              autoComplete="off"
              type="text"
              placeholder={t('signUp.inputs.username')}
              value={username}
              aria-invalid={validUsername ? "false" : "true"}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </InputBox>

          <InputBox>
            <Inputs
              theme={theme}
              id="email"
              type="text"
              placeholder={t('signUp.inputs.email')}
              value={email}
              aria-invalid={validEmail ? "false" : "true"}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </InputBox>

          <InputBox>
            <Inputs
              id="password"
              theme={theme}
              type="password"
              placeholder={t('signUp.inputs.password')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              aria-invalid={validPassword ? "false" : "true"}
              aria-describedby="pwdnote"
              required
            />
          </InputBox>

          <InputBox>
            <Inputs
              id="confirm_password"
              theme={theme}
              type="password"
              placeholder={t('signUp.inputs.confirm')}
              value={matchPassword}
              onChange={(e) => setMatchPassword(e.target.value)}
              aria-invalid={validMatchPassword ? "false" : "true"}
              aria-describedby="confirmnote"
              required
            />
          </InputBox>

          <Policy>
            <input type="checkbox" required />
            <Terms>
              {t('signUp.footer.accept')}{" "}
              <Redirection to="/termsandcondition" theme={theme}>
                {t('signUp.footer.terms')}
              </Redirection>
            </Terms>
          </Policy>
          <ButtonRegisterNow>
            <InputRegister theme={theme} type="Submit" value={t('signUp.footer.button')} />
          </ButtonRegisterNow>
          <AlreadySignUpMessage>
            {t('signUp.footer.alreadySign')}{" "}
            <Redirection to="/login" theme={theme}>
              {t('signUp.footer.redirection')}
            </Redirection>
          </AlreadySignUpMessage>
        </FormSignUp>
        {errMsg && <ErrorMessage>{errMsg}</ErrorMessage>}
      </SignUpContainer>
    </SignUpWrapper>
  );
}

export default withNamespaces()(SignUp);
