import styled from "styled-components"
import colors from "../../utils/style/colors"
import { useTheme } from "../../utils/hooks/useTheme"
import { useState, useRef, useEffect } from "react"
import useAxiosMultipart from "../../utils/hooks/useAxiosMultipart"
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate"
import { LuImagePlus, LuLink, LuCode2, LuAlignEndHorizontal, LuDelete} from "react-icons/lu";
import { useNavigate } from "react-router-dom"
import { truncateString } from "../../utils/textAnalyser/truncateString"
import NavHeader from "../../components/Header/NavHeader"
import Icon from "../../components/Icon"
import { useAuth } from "../../utils/hooks/useAuth"
import { withNamespaces } from "react-i18next"
import { Trans } from "react-i18next"

const CREATEPOST_URL = "/api/post/create";

const SubmitWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 65px;
`

const Containers = styled.div`
    width: 550px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const RulesContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const SubmitContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const ClassicButton = styled.button`
  align-self: flex-end;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const PostTextarea = styled.textarea`
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid
      ${({ theme }) =>
        theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  background: none;
  height: 130px;
  width: 100%;
  font-family: sans-serif;
  font-size: 1rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  resize: none;
`;

const ComposeForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
`;

const IconContainer = styled.div`
  align-self: flex-start;
  display: flex;
  padding-left: 10px;
  gap: 10px;
  font-size: 0.8rem;
`;

const Title = styled.div`
    font-size: 1rem;
    font-weight: 500;
    padding-bottom: 5px;
`

const Subtitle = styled.div`
    font-size: 0.8rem;
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const FileInput = styled.input`
  display: none;
`;

const AddImageIcon = styled(LuImagePlus)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const LinkIcon = styled(LuLink)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const CodeIcon = styled(LuCode2)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const PollIcon = styled(LuAlignEndHorizontal)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const DeleteImageIcon = styled(LuDelete)`
  cursor: pointer;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const HorizontalDivider = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
`

const UnderlineSpan = styled.span`
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
`

const FileInputLabel = styled.label`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
`;

const ErrorMessage = styled.div`
    color: red;
    padding: 15px;
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
`

const Selector = styled.select`
  width: 100%;
    align-self: flex-end;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 1rem;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
    }
`

const Option = styled.option`
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: none;
  outline: none;
`

function Submit({ t }){
    const {theme} = useTheme();
    const {auth} = useAuth();

    const [postText, setPostText] = useState("");
    const [file, setFile] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [clubsJoined, setClubsJoined] = useState();
    const [selectedOption, setSelectedOption] = useState();

    const axiosMultipart = useAxiosMultipart();
    const axiosPrivate = useAxiosPrivate();

    const navigate = useNavigate();
    const fileInputRef = useRef(null);

    const GETCLUBS_URL = `api/club/getClubsMember/${auth.username}`;

    const handlePostChange = (e) => {
        setPostText(e.target.value);
    };

    const handleFileInputChange = (e) => {
      setFile(e.target.files[0]);
    };
  
    const handleAddImageClick = () => {
      fileInputRef.current.click();
    };

    const removeFileInput = () => {
      setFile();
    }

    const handlePostSubmit = async (e) => {
      e.preventDefault();

      console.log(selectedOption);
      if (!selectedOption || selectedOption === "") {
        setErrorMessage("Please select a valid option.");
        return;
      }

      const formData = new FormData();
      formData.append("content", postText);
      formData.append("option", selectedOption);
      if (file){
        formData.append("image", file);
      }

      try {
        await axiosMultipart.post(CREATEPOST_URL, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        })
        
        navigate(`../home`);

      } catch (error) {
        
        setErrorMessage(error.message);
        console.log(error);
      }
    };

    useEffect(() => {
        try {
            axiosPrivate
            .get(GETCLUBS_URL)
            .then((response) => {
                setClubsJoined(response.data.clubs[0]);
            })
            .catch((err) => {
                console.error("Erreur lors de la requête :", err);
  
                if (err.response) {
                console.log(err.response);
                console.log(err.response.data);
                console.log(err.response.status);
                console.log(err.response.headers);
                } else {
                console.error("Erreur générique :", err.message);
                }
            });
        } catch (err) {
            console.error("Erreur lors de la requête :", err);
        }
    }, []);

    useEffect(() => {
      document.title = `Havens - Create Post`;
    }, []);

    return (
        <SubmitWrapper>
            <Containers>
                <NavHeader mention={t('utils.header.message')}/>
                <RulesContainer theme={theme}>
                    <Title>{t('submit.header.title')}</Title>
                    <Subtitle theme={theme}>{t('submit.header.subtitle1')}</Subtitle>
                    <Subtitle theme={theme}>{t('submit.header.subtitle2')}</Subtitle>
                    <Subtitle theme={theme}>{t('submit.header.subtitle3')}</Subtitle>
                    <Subtitle theme={theme}>
                      <Trans i18nKey="submit.header.subtitle4">
                        4 - Read the <UnderlineSpan theme={theme}>community's rules</UnderlineSpan>
                      </Trans>
                    </Subtitle>
                </RulesContainer>
                {errorMessage && (
                  <ErrorMessage theme={theme}>{errorMessage}</ErrorMessage>
                )}
                <SubmitContainer theme={theme}>
                    <Title>{t('submit.create.title')}</Title>
                    <Selector theme={theme} name="option" required onChange={e => setSelectedOption(e.target.value)}>
                      <Option theme={theme} value="" disabled selected>{t('submit.create.option')}</Option>
                      { clubsJoined && (
                        clubsJoined.map((club, index) => (
                          <Option key={index} theme={theme} value={club.club}>
                              {'/' + club.club}
                          </Option>
                      ))
                      )}
                    </Selector>
                    <ComposeForm theme={theme}>
                        <PostTextarea
                            placeholder={t('submit.create.content')}
                            value={postText}
                            onChange={handlePostChange}
                            rows={4}
                            theme={theme}
                        />
                        {file && (
                          <div>-{'>'} {truncateString(file.name, 30)}</div>
                        )}
                        <IconContainer>
                          <FileInputLabel onClick={handleAddImageClick}>
                            <Icon>
                              <AddImageIcon theme={theme}/>
                            </Icon>
                          </FileInputLabel>
                          <FileInput
                            type="file"
                            onChange={handleFileInputChange}
                            ref={fileInputRef}
                          />
                          <Icon>
                            <LinkIcon theme={theme}/>
                          </Icon>
                          <Icon>
                            <PollIcon theme={theme}/>
                          </Icon>
                          <Icon>
                            <CodeIcon theme={theme}/>
                          </Icon>
                          {file && (
                            <Icon>
                              <DeleteImageIcon theme={theme} onClick={removeFileInput}/>
                            </Icon>
                          )}
                        </IconContainer>
                        <HorizontalDivider theme={theme} />
                        <ClassicButton onClick={handlePostSubmit} theme={theme}>
                        {t('submit.buttons.post')}
                        </ClassicButton>
                    </ComposeForm>
                </SubmitContainer>
            </Containers>
        </SubmitWrapper>
    );
}

export default withNamespaces()(Submit);