import styled from "styled-components"
import { useTheme } from "../../../utils/hooks/useTheme"
import colors from "../../../utils/style/colors";
import MentionText from "../../Utils/MentionText";
import { Link } from "react-router-dom";
import { truncateString } from "../../../utils/textAnalyser/truncateString";

const CardContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 16px;
    width: 100%;
    overflow: hidden;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const SmallCardContainer = styled.div`
    border-radius: 10px;
    padding: 5px;
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    overflow: hidden;
    height: 60px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
  display: flex;
`;

const Avatar = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const Username = styled.h3`
    margin: 0;
    color: ${({ theme }) => theme === "light" ? colors.secondary : colors.primary};
    font-weight: 600;
    font-size: 0.8rem;

    &:hover{
        text-decoration: underline;
    }
`;

const Description = styled.div`
    color: ${({ theme }) => theme === "light" ? "#666" : "#ddd"};
    font-size: 0.8rem;
`;

const FollowersCount = styled.span`
    color: ${({ theme }) => theme === "light" ? "#666" : "#ddd"};
    font-size: 0.8rem;
`;

const SpanText = styled.span`
    color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    font-size: 0.8rem;
`

function UserCard({ username, avatar, description, followers_count, small}){

    const { theme } = useTheme();

    const avatarSrc = avatar || 'https://institutcommotions.com/wp-content/uploads/2018/05/blank-profile-picture-973460_960_720-1.png';

    return (
        <StyledLink to={`../../profile/${username}`}>
            {small ? (
                <SmallCardContainer theme={theme}>
                    <Avatar src={avatarSrc} alt={`${username}'s avatar`} />
                    <UserInfo>
                        <Username theme={theme}>{username}</Username>
                        {description && (
                            <Description theme={theme}><MentionText text={truncateString(description, 50)}/></Description>
                        )}
                        <FollowersCount theme={theme}><SpanText theme={theme}>{followers_count}</SpanText> followers</FollowersCount>
                    </UserInfo>
                </SmallCardContainer>
            ) : (
                <CardContainer theme={theme}>
                    <Avatar src={avatarSrc} alt={`${username}'s avatar`} />
                    <UserInfo>
                        <Username theme={theme}>{username}</Username>
                        {description && (
                            <Description theme={theme}><MentionText text={truncateString(description, 50)}/></Description>
                        )}
                        <FollowersCount theme={theme}><SpanText theme={theme}>{followers_count}</SpanText> followers</FollowersCount>
                    </UserInfo>
                </CardContainer>
            )}
        </StyledLink>
    )
};

export default UserCard