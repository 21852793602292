import styled from "styled-components";
import PostComposer from "../../components/Post/PostComposer";
import TimelineBody from "../../components/Timeline/TimelineBody";
import colors from "../../utils/style/colors";
import { useTheme } from "../../utils/hooks/useTheme";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useAuth } from "../../utils/hooks/useAuth";
import UserSuggestion from "../../components/User/UserSuggestion"
import { fictiveTimeline } from "../../components/Timeline/TimelineBody/fictiveTimeline";
import VerticalClubs from "../../components/Club/VerticalClubs";
import { withNamespaces } from "react-i18next";

const GETALLPOSTS_URL = "/api/post/getPosts";

const HomeWrapper = styled.div`
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  justify-content: center;
  gap: 15px;
`

const HomeContainer = styled.div`
  min-height: 100vh;
  max-width: 550px;
`;

const Separator = styled.div`
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-top: 0;
  border-bottom: 0;
`;

const UserSuggestionContainer = styled.div`
  position: sticky;
  max-width: 300px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const VerticalClubsContainer = styled.div`
  position: sticky;
  display: flex;
  gap: 15px;
  flex-direction: column;
`

function Home({ t }) {
  const { theme } = useTheme();
  const [posts, setPosts] = useState([]);
  const [reloadFlag, setReloadFlag] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const addNewPost = (newPost) => {
    setPosts((prevPosts) => [newPost, ...prevPosts]);
  };

  const handlePostDelete = () => {
    setReloadFlag(prevFlag => !prevFlag);
  };

  useEffect(() => {
    if (auth.accessToken) {
      try {
        axiosPrivate
          .get(GETALLPOSTS_URL, {})
          .then((response) => {
            setPosts(response.data.lastPosts);
          })
          .catch((err) => {
            // Gérez les erreurs ici
            console.error("Erreur lors de la requête :", err);

            if (err.response) {
              console.log(err.response);
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
            } else {
              console.error("Erreur générique :", err.message);
            }
          });
      } catch (err) {
        console.error("Erreur lors de la requête :", err);
      }
    } else {
      setPosts([]);
    }
  }, [auth.accessToken, reloadFlag]);

  useEffect(() => {
    document.title = `Havens - Timeline`;
  }, []);

  return (
    <HomeWrapper>
      {auth.accessToken && (
        <VerticalClubsContainer>
          <VerticalClubs />
        </VerticalClubsContainer>
      )}
      <HomeContainer>
        <PostComposer text={`${t('home.createPost.whats')} ${auth.username ? auth.username + ' ' : ''}?`} onNewPost={addNewPost} />
        <Separator theme={theme} />
        {auth.accessToken ? (
          <TimelineBody posts={posts} commentStatus={false} end={true} onPostDelete={handlePostDelete}/>
        ) : (
          <TimelineBody posts={fictiveTimeline} commentStatus={false} end={true} fictive={true} onPostDelete={handlePostDelete}/>
        )}
      </HomeContainer>
      {auth.accessToken && (
        <UserSuggestionContainer>
          <UserSuggestion />
        </UserSuggestionContainer>
      )}
    </HomeWrapper>
  );
}

export default withNamespaces()(Home);
