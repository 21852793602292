import styled from "styled-components";
import NavHeader from "../../components/Header/NavHeader";
import { useTheme } from "../../utils/hooks/useTheme";
import colors from "../../utils/style/colors";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"
import ErrorRequest from "../../components/Utils/ErrorRequest";
import { withNamespaces } from "react-i18next";
import { Trans } from "react-i18next";

const CREATECLUB_URL = "/api/club/createClub";

const CreateContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 65px;
    max-width: 550px;
    gap: 15px;
    min-height: 100vh;
`;

const CreateClubContainer = styled.div`
  border: 1px solid ${({ theme }) =>
    theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
    font-size: 25px;
    font-weight: 500;
    padding-bottom: 5px;
`

const Subtitle = styled.div`
  font-size: 18px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`

const ClubNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-bottom: 1px solid
    ${({ theme }) =>
    theme === "light" ? colors.borderColorLight : colors.borderColorDark};
`;

const Slash = styled.div`
  font-size: 20px;
  margin-right: 10px;
`;

const ClubNameInput = styled.input`
  flex: 1;
  border: none;
  background: none;
  font-size: 18px;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};
  outline: none;
`;

const RulesContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

const UnderlineSpan = styled.span`
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.8rem;
`

const RulesInfos = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`
const HorizontalDivider = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
`

const ClassicButton = styled.button`
  align-self: flex-end;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ButtonContainer = styled.div`
  padding: 15px;
  display: flex;
  justify-content: flex-end;
`

function Create({ t }) {
  const { theme } = useTheme();

  const [clubName, setClubName] = useState();
  const [errMsg, setErrMsg] = useState();

  const axiosPrivate = useAxiosPrivate();

  const navigate = useNavigate();

  const handleClubChange = (e) => {
    setClubName(e.target.value);
  };

  const handleClubSubmit = async (e) => {
    e.preventDefault();


    try {
      await axiosPrivate.post(CREATECLUB_URL, {
        clubName: clubName,
      });
      
      navigate(`../club/${clubName}`);

    } catch (err) {
      
      if (err.response.status === 404) {
        setErrMsg("This club already exists.");
      } else {
        setErrMsg("Server Error, please retry later");
      }

    }
  };

  useEffect(() => {
    document.title = `Havens - Create Club`;
  }, []);

  return (
    <CreateContainer>
      <NavHeader mention={t('utils.header.message')} />
      {errMsg && (
        <ErrorRequest message={errMsg}/>
      )}
      <RulesContainer theme={theme}>
        <RulesInfos>
          <Title>{t('create.header.title')}</Title>
          <Subtitle theme={theme}>{t('create.header.subtitle1')}</Subtitle>
          <Subtitle theme={theme}>{t('create.header.subtitle2')}</Subtitle>
          <Subtitle theme={theme}>{t('create.header.subtitle3')}</Subtitle>
          <Subtitle theme={theme}>
            <Trans i18nKey="create.header.subtitle4">
              4 - Read the <UnderlineSpan theme={theme}>community's rules</UnderlineSpan>
            </Trans>
          </Subtitle>
        </RulesInfos>
      </RulesContainer>
      <CreateClubContainer theme={theme}>
        <RulesInfos>
          <Title>{t('create.create.title')}</Title>
          <Subtitle theme={theme}>{t('create.create.subtitle')}</Subtitle>
        </RulesInfos>
        <ClubNameContainer theme={theme}>
          <Slash>/</Slash>
          <ClubNameInput
            type="text"
            placeholder={t('create.create.content')}
            theme={theme}
            onChange={handleClubChange}
          />
        </ClubNameContainer>
        <ButtonContainer>
          <ClassicButton theme={theme} onClick={handleClubSubmit}>
            {t('create.buttons.submit')}
          </ClassicButton>
        </ButtonContainer>
      </CreateClubContainer>
    </CreateContainer>
  );
}

export default withNamespaces()(Create);
