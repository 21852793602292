import PropTypes from "prop-types";
import styled from "styled-components";
import PostBody from "../../Post/PostBody";
import EmptyTimeline from "../EmptyTimeline";
import { useTheme } from "../../../utils/hooks/useTheme";
import { useState, useEffect } from "react";

const TimelineContainer = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;
  padding-top: 5px;
  border-radius: 10px;
`;

const TimelineItem = styled.li`
  list-style: none;
`;

const TimelineWrapper = styled.div`
  
`

function TimelineBody({ posts, end, color, onPostDelete}) {
  
  const { theme } = useTheme();
  const [updatedTimeline, setUpdateTimeline] = useState([]);

  const hasNoPosts = !posts || posts.length === 0;

  useEffect(() => {
    
    setUpdateTimeline(posts);

  }, [posts]);

  const addToTimeline = (newPost) => {
    setUpdateTimeline((prevTimeline) => {
      if (newPost.parent_id) {
        const parentIndex = prevTimeline.findIndex((post) => post.post_id === newPost.parent_id);

        if (parentIndex !== -1) {
          const updatedTimeline = [...prevTimeline];
          updatedTimeline[parentIndex] = {
            ...updatedTimeline[parentIndex],
            comment_count: updatedTimeline[parentIndex].comment_count + 1,
          };
          return [updatedTimeline[0], newPost, ...updatedTimeline.slice(1)];
        }
      }

      return [newPost, ...prevTimeline];
    });
  };

  return (
    <TimelineWrapper>
      <TimelineContainer theme={theme}>
        {!hasNoPosts &&
          updatedTimeline.map(
            ({
              id,
              username,
              avatar,
              post_id,
              parent_id,
              club,
              content,
              created_at,
              level,
              comment_count,
              repost_count,
              like_count,
              isLiked,
              image,
            }) => (
              <TimelineItem key={id} className="timeline-item">
                <PostBody
                  onPostSubmit={addToTimeline}
                  onPostDelete={onPostDelete}
                  username={username}
                  club={club}
                  avatar={avatar}
                  created_at={created_at}
                  post_id={post_id}
                  parent_id={parent_id}
                  content={content}
                  color={color}
                  level={level}
                  comment_count={comment_count}
                  repost_count={repost_count}
                  like_count={like_count}
                  isLiked={isLiked}
                  image={image}
                >
                  {content}
                </PostBody>
              </TimelineItem>
            ),
          )}

        {end && <EmptyTimeline />}
      </TimelineContainer>
    </TimelineWrapper>
  );
}

TimelineBody.propTypes = {
  posts: PropTypes.array,
  onPostDelete: PropTypes.func,
};

export default TimelineBody;
