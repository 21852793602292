import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { FiX } from "react-icons/fi";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosMultipart from "../../../utils/hooks/useAxiosMultipart";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useParams } from "react-router-dom";
import Icon from "../../Icon";
import { withNamespaces } from "react-i18next";

const EditProfileWrapper = styled.div`
  min-height: 100vh;
  max-width: 550px;
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FiArrowIcon = styled(FiX)`
  font-size: 1.7rem;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light" ? colors.primaryHover : colors.secondaryHover};
  }
`;

const Title = styled.div`
  margin-left: 10px;
  font-size: 0.8rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`;

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const SaveButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) => (theme === "light" ? colors.secondary : colors.primary)};
  background-color: ${({ theme }) => (theme === "light" ? colors.primary : colors.secondary)};
  border: 1px solid ${({ theme }) => (theme === 'light' ? colors.borderColorLight : colors.borderColorDark)};
  display: flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  padding: 6px 12px;
  height: 40px;
  font-size: 0.8rem;
  border-radius: 10px;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => (theme === "light" ? colors.primaryHover : colors.secondaryHover)};
  }
`;

const InputBox = styled.div`
  height: 52px;
  margin: 50px 0;
  max-width: 500px;
  margin-left: 15px;
  margin-right: 50px;
  font-size: 0.8rem;
`;

const InputTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 5px;
  font-size: 0.8rem;
`;

const Inputs = styled.input`
  height: 100%;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  font-weight: 400;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  text-indent: 15px;
  font-size: 0.8rem;

  &::placeholder {
    font-size: 0.8rem;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const BannerImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const AvatarInput = styled.input`
    
`;

const BannerInput = styled.input`
  
`;

const ErrorMessage = styled.div`
  padding-left: 15px;
  margin-top: 20px;
  font-size: 0.8rem;
  color: red;
  text-align: center;
`

const FileTitle = styled.div`
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 50px;
    font-size: 0.8rem;
` 

const AvatarUpload = styled.label`
  font-size: 0.8rem;
  margin-top: 10px;
  margin-left: 15px;
  display: inline-block; 
  width: 200px; 
  height: 200px; 
  border: 2px dashed
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.2s;
  position: relative;
  border-radius: 10px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const BannerUpload = styled.label`
  margin-top: 10px;
  margin-left: 15px;
  display: inline-block; 
  width: 500px; 
  height: 200px; 
  border: 2px dashed
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.2s;
  position: relative;
  border-radius: 10px;
  font-size:0.8rem;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const Content = styled.div`
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
`

function EditClub({ t }) {
  const { theme } = useTheme();
  const { auth} = useAuth();
  const navigate = useNavigate();
  const { club } = useParams();

  const GETCLUB_URL = `api/club/getClub/${club}`;

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const [desc, setDesc] = useState();
  const [avatar, setAvatar] = useState();
  const [avatarPreview, setAvatarPreview] = useState("https://institutcommotions.com/wp-content/uploads/2018/05/blank-profile-picture-973460_960_720-1.png");
  const [previousAvatar, setPreviousAvatar] = useState('');
  const [banner, setBanner] = useState();
  const [bannerPreview, setBannerPreview] = useState();
  const [previousBanner, setPreviousBanner] = useState('');

  const axiosMultipart = useAxiosMultipart();
  const axiosPrivate = useAxiosPrivate();

  const handleGoBack = () => {
    navigate(-1);
  };

  function handleAvatar(e) {
    setAvatar(e.target.files[0]);
    
    const reader = new FileReader();

    reader.onload = (e) => {
      setAvatarPreview(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  }

  function handleBanner(e) {
    setBanner(e.target.files[0]);
    
    const reader = new FileReader();

    reader.onload = (e) => {
      setBannerPreview(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  }

  const resetForm = () => {
    setDesc();
    setAvatar();
    setAvatarPreview();
    setPreviousAvatar();
    setBanner();
    setBannerPreview();
    setPreviousBanner();
  };

  useEffect(() => {
    if (formSubmitted) {
      resetForm();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (avatar){
      formData.append("avatar", avatar, avatar.name);
      formData.append("previousAvatar", previousAvatar);
    }

    if (banner){
      formData.append("banner", banner, banner.name);
      formData.append("previousBanner", previousBanner);
    }

    formData.append("desc", desc);

    try{
      await axiosMultipart.post(`api/club/updateClub/${club}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })

      setFormSubmitted(true);
      navigate(`../../club/${club}`);

    } catch (error) {
        if (error.response) {
        
          if (error.response.status === 400) {
            setErrMsg("Invalid file format. Please select another file.");
          } else {
            setErrMsg("Server Error, please retry later");
          }

      } else {
  
        setErrMsg("Server Error, please retry later");
      }
    }
  };

  useEffect(() => {
      try {
          axiosPrivate
          .get(GETCLUB_URL)
          .then((response) => {
              setDesc(response.data.infoClub[0].description);
              if (response.data.infoClub[0].avatar !== ''){
                setAvatarPreview(response.data.infoClub[0].avatar);
              }
              if (response.data.infoClub[0].banner !== ''){
                setBannerPreview(response.data.infoClub[0].banner);
              }
          })
          .catch((err) => {
              console.error("Erreur lors de la requête :", err);

              if (err.response) {
              console.log(err.response);
              console.log(err.response.data);
              console.log(err.response.status);
              console.log(err.response.headers);
              } else {
              console.error("Erreur générique :", err.message);
              }
          });
      } catch (err) {
          console.error("Erreur lors de la requête :", err);
      }
  }, [club]);

  useEffect(() => {
    document.title = `Havens - Edit ${club}`;
  }, [club]);

  return (
    <EditProfileWrapper theme={theme}>
    <Header theme={theme}>
      <LeftHeader>
        <Icon>
            <FiArrowIcon theme={theme} onClick={handleGoBack}/>
        </Icon>
        <Title theme={theme}>{t('editClub.header.edit') + ' ' + club}</Title>
      </LeftHeader>
      <SaveButton
        theme={theme}
        onClick={handleSubmit}
      >{t('editClub.header.button')}</SaveButton>
    </Header>
      { errMsg && (
        <ErrorMessage>{errMsg}</ErrorMessage>
      )}
    <Content theme={theme}>
      <FileTitle theme={theme}>{t('editClub.header.avatar')}</FileTitle>
      <AvatarUpload theme={theme}>
        {avatarPreview ? (
          <AvatarImage src={avatarPreview} alt="Avatar" />
        ) : (
          <p>{t('editClub.inputs.avatar')}</p>
        )}

        <AvatarInput
          type="file"
          name="avatar"
          onChange={handleAvatar}
        />
      </AvatarUpload>

      <FileTitle theme={theme}>{t('editClub.header.banner')}</FileTitle>
      <BannerUpload theme={theme}>
          {bannerPreview ? (
            <BannerImage src={bannerPreview} alt="Banner" />
          ) : (
            <p style={{fontSize: '0.8rem'}}>{t('editClub.inputs.banner')}</p>
          )}

          <BannerInput
            type="file"
            name="banner"
            onChange={handleBanner}
          />
      </BannerUpload>

        <InputBox>
          <InputTitle>{t('editClub.header.club')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="name"
            placeholder={`${club}`}
            disabled
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editClub.header.desc')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="desc"
            placeholder={t('editClub.inputs.desc')}
            value={desc}
            onChange={(e) => setDesc(e.target.value)}
            required
          />
        </InputBox>
        </Content>
    </EditProfileWrapper>
  );
}

export default withNamespaces()(EditClub);
