import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { useTheme } from "../../../utils/hooks/useTheme"
import { withNamespaces } from "react-i18next"

const CategorieSelectorContainer = styled.div`

`

const CategorieBanner = styled.div`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  background-color: ${({theme}) => theme === 'light' ? colors.primaryBackground : colors.secondaryBackground};
`;

const Categorie = styled.div`
  text-align: center;
  display: flex;
  cursor: pointer;
  color: ${({ active, theme }) => active !== '' ? (theme === 'light' ? colors.secondary : colors.primary) : (theme === 'light' ? colors.primaryText : colors.secondaryText)};
  text-decoration: ${({active}) => active ? 'underline' : 'none'};
  text-decoration-color: ${colors.greyContrast};
`;

function DiscoverSelector({DiscoverStatus, toggleDiscoverStatus, t}){

    const {theme} = useTheme();
 
    return (
        <CategorieSelectorContainer>
            <CategorieBanner theme={theme}>
                <Categorie 
                    theme={theme} 
                    onClick={() => toggleDiscoverStatus("Clubs") }
                    active={DiscoverStatus === "Clubs" ? "active" : ""}
                >
                    {t('discover.selector.clubs')}
                </Categorie>
                <Categorie
                    theme={theme}
                    onClick={() => toggleDiscoverStatus("Users")}
                    active={DiscoverStatus === "Users" ? "active" : ""}
                >
                    {t('discover.selector.users')}
                </Categorie>
                <Categorie
                    theme={theme}
                    onClick={() => toggleDiscoverStatus("Trends")}
                    active={DiscoverStatus === "Trends" ? "active" : ""}
                >
                    {t('discover.selector.trends')}
                </Categorie>
            </CategorieBanner>
        </CategorieSelectorContainer>
    )
};

export default withNamespaces()(DiscoverSelector);