import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useTheme } from "../../../utils/hooks/useTheme";

const CREATECOMMENT_URL = "/api/post/createComment";

const ComposeForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;

  &:first-child {
    border: 1px solid
      ${({ theme }) =>
        theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  }
`;

const CommentComposerContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CommentTextarea = styled.textarea`
  background: none;
  border: 0;
  outline: none;
  width: 100%;
  min-height: 70px;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  resize: none;
  font-size: 0.8rem;

  &::placeholder {
    font-size: 16px;
  }
`;

const CommentButton = styled.button`
  flex: 0 0 auto;
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

function CommentComposer({ counters, onPostSubmit }) {
  const { theme } = useTheme();
  const { auth } = useAuth();
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();

  const [commentText, setCommentText] = useState("");

  const handlePostChange = (e) => {
    setCommentText(e.target.value);
  };

  function handleCommentSubmission(newComment) {
    console.log('call comment submission');
    onPostSubmit(newComment);
  }

  const handlePostSubmit = async (e) => {
    if (typeof onPostSubmit === 'function') {
      if (auth.username) {
        try {
          const response = await axiosPrivate.post(CREATECOMMENT_URL, {
            parent_id: counters.post_id,
            club: counters.club,
            content: commentText,
          });

          response.data.newComment[0].isLiked = 0;

          console.log(response.data.newComment);

          setCommentText("");
          handleCommentSubmission(response.data.newComment[0]);

        } catch (err) {
          // Gérez les erreurs ici
          console.error("Erreur lors de la requête :", err);

        }
      } else {
        navigate("/signup");
      }
    } else {
      console.error('onPostSubmit is not a function'); // Log an error message
    }
  };

  return (
    <ComposeForm theme={theme}>
      <CommentComposerContainer>
        <CommentTextarea
          placeholder={counters.text}
          value={commentText}
          onChange={handlePostChange}
          rows={4}
          theme={theme}
        />
      </CommentComposerContainer>
      <CommentButton onClick={handlePostSubmit} theme={theme}>
        Submit
      </CommentButton>
    </ComposeForm>
  );
}

export default CommentComposer;
