import styled from "styled-components";
import TimelineBody from "../../Timeline/TimelineBody";
import { Link, useParams, useNavigate } from "react-router-dom";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { useState, useEffect } from "react";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { FiArrowLeft } from "react-icons/fi";
import { useAuth } from "../../../utils/hooks/useAuth";
import NavHeader from "../../Header/NavHeader";

const CommentWrapper = styled.div`
  min-height: 100vh;
  max-width: 550px;
  margin: 0 auto;
`;

const CommentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 65px;
`;

const Arrow = styled(FiArrowLeft)`
  font-size: 1.7rem;
  margin-right: 10px;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light" ? colors.secondaryHover : colors.primaryHover};
  }
`;

const Banner = styled.div`
  padding-top: 10px;
  padding-left: 10px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-bottom: none;
  gap: 5px;
  display: flex;
  align-items: center;
`;

const BannerMessage = styled.div`
  padding-bottom: 4px;
`;

function Comment() {

  const { username } = useParams();
  const { id } = useParams();
  const { theme } = useTheme();
  const { auth } = useAuth();
  const navigate = useNavigate();

  const [reloadFlag, setReloadFlag] = useState(false);
  const [comments, setComments] = useState([]);

  const axiosPrivate = useAxiosPrivate();

  const GETALLCOMMENTS_URL = `/api/post/getCommentsById/${id}`;

  const handleGoBack = () => {
    navigate(-1);
  };

  const handlePostDelete = () => {
    setReloadFlag(prevFlag => !prevFlag);
  };

  useEffect(() => {
    try {
      axiosPrivate
        .get(GETALLCOMMENTS_URL)
        .then((response) => {
          setComments(response.data.posts);
        })
        .catch((err) => {
          // Gérez les erreurs ici
          console.error("Erreur lors de la requête :", err);

          if (err.response) {
            console.log(err.response);
          } else {
            console.error("Erreur générique :", err.message);
          }
        });
    } catch (err) {
      console.error("Erreur lors de la requête :", err);
    }
  }, [id]);

  return (
    <CommentWrapper>
      <CommentContainer>
        <NavHeader mention={'@' + username}/>
        <TimelineBody posts={comments} end={true} onPostDelete={handlePostDelete}/>
      </CommentContainer>
    </CommentWrapper>
  );
}

export default Comment;
