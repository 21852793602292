import styled from "styled-components";
import { useTheme } from "../../../utils/hooks/useTheme";
import { Loader } from "../../../utils/style/Atoms";
import { useContext } from "react";

const ErrorWrapper = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 30px;
  min-height: 100vh;
`;

const ErrorTitle = styled.div`
  padding-top: 100px;
  padding-bottom: 15px;
`;

const ErrorSubtitle = styled.div`
  padding-top: 15px;
  text-align: center;
`;

function Error() {
  const { theme } = useTheme();

  return (
    <ErrorWrapper>
      <ErrorTitle>Oops...</ErrorTitle>
      <Loader data-testid="loader" theme={theme} />
      <ErrorSubtitle>
        It seems that the page you're looking for does not exist
      </ErrorSubtitle>
    </ErrorWrapper>
  );
}

export default Error;
