import styled from "styled-components";
import { withNamespaces } from "react-i18next";

const TimeSpan = styled.span`
    font-size: 0.8rem;
`


function TimeElapsedFormatter({ createdTimeString, t }) {
  const formatTimeElapsed = () => {
    const createdTime = new Date(createdTimeString);
    const currentTime = Date.now();

    const timeDifferenceInSeconds = Math.floor(
      (currentTime - createdTime) / 1000,
    );

    if (timeDifferenceInSeconds < 60) {
      return `${timeDifferenceInSeconds}s`;
    } else if (timeDifferenceInSeconds < 3600) {
      const minutes = Math.floor(timeDifferenceInSeconds / 60);
      return `${minutes}m`;
    } else if (timeDifferenceInSeconds < 86400) {
      const hours = Math.floor(timeDifferenceInSeconds / 3600);
      return `${hours}h`;
    } else {
      const days = Math.floor(timeDifferenceInSeconds / 86400);
      return `${days}d`;
    }
  };

  const formattedTime = formatTimeElapsed();

  return <TimeSpan>{formattedTime}</TimeSpan>;
}

export default withNamespaces()(TimeElapsedFormatter);
