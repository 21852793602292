// fictiveTimeline
const fictiveTimeline = [
    {
        username: "Havens",
        post_id: 1,
        content: "Hello world!",
        created_at: "2023-11-07T12:00:00",
        comment_count: 5,
        repost_count: 2,
        like_count: 10,
        isLiked: true,
        club: 'everyone',
        avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Can I introduce myself ?",
      created_at: "2023-11-07T12:00:00",
      comment_count: 9,
      repost_count: 3,
      like_count: 4,
      image: "https://i.redd.it/xu6ph5bz77xb1.jpg",
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "I am a brand new social app !",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "I'm obviously better than Twitter and Reddit",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      image:'https://sportshub.cbsistatic.com/i/2021/08/09/37af4520-220a-48dc-bf7d-510fb7451ca0/juan-1266367.jpg',
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Why so, you may ask ?",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "because us, we don't track your actions on the app",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      image: 'https://staticg.sportskeeda.com/editor/2021/04/f7504-16196838481260-800.jpg',
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "The idea is to reduce the interface possibilities to focus on the main goal of a social media.",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "No deep algorithms.",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "We will also introduce club-based communities to connect with people who share your passions.",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "One last thing.",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
    {
      username: "Havens",
      post_id: 1,
      content: "Your Voice Matters. Share It.",
      created_at: "2023-11-07T12:00:00",
      comment_count: 214,
      repost_count: 450,
      like_count: 1500,
      isLiked: true,
      club: 'everyone',
      avatar: 'http://api.havens.fr/avatars/avatar_1710581949455SimpleLogo.png'
    },
  ];
  
  export { fictiveTimeline };
  