import styled from "styled-components";
import { AiOutlineUser } from "react-icons/ai";
import { useTheme } from "../../../utils/hooks/useTheme";
import { useState } from "react";
import colors from "../../../utils/style/colors";
import Switch from "../../Utils/Switch";
import Icon from "../../Icon";
import { Link } from "react-router-dom";
import i18n from '../../../i18n';
import { withNamespaces } from "react-i18next";

const ScrollDownMenu = styled.div`
  width: 150px;
  padding: 5px;
  margin: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-118px);
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  outline: 1px solid;
  outline-color: ${({ theme }) =>
    theme === "light" ? colors.primaryHover : colors.secondaryHover};
  border-radius: 10px;
  transform-origin: top center;
  margin-right: -50%; /* Ajustez cette valeur selon vos besoins */
`;


const OptionsList = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const OptionItem = styled.div`
  font-size: 0.8rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 15px;
  color: #9e9ea7;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light"
        ? colors.primaryContrastHover
        : colors.secondaryContrastHover};
  }
`;

const OptionCategorieTitle = styled.div`
  padding-bottom: 5px;
`;

const CategorieContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
`;

const Languages = styled.div`
  display: flex;
  gap: 15px;
`;

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
`;

function UnauthSettings({ t }) {
  const { theme, toggleTheme } = useTheme();

  const [isUserOpen, setIsUserOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const toggleUser = () => {
    setIsUserOpen(!isUserOpen);
  };

  const handleSwitchChange = () => {
    toggleTheme();
    setIsChecked(!isChecked);
  };

  const changeLanguage = (ln) => {
    i18n.changeLanguage(ln);
  }

  return (
    <IconContainer>
      <Icon>
        <AiOutlineUser theme={theme} onClick={toggleUser} />
      </Icon>
      {isUserOpen && (
        <ScrollDownMenu theme={theme}>
          <OptionCategorieTitle>{t('unauthNavbar.header.options')}</OptionCategorieTitle>
          <OptionsList>
            <CategorieContainer>
              <OptionItem theme={theme}>
                {t('unauthNavbar.header.mode')}
                <Switch
                  handleChange={handleSwitchChange}
                  isChecked={isChecked}
                />
              </OptionItem>
            </CategorieContainer>
            <CategorieContainer>
              <Languages>
                <img
                  src="https://flagcdn.com/w40/fr.png"
                  srcSet="https://flagcdn.com/w40/fr.png 2x"
                  width="40"
                  alt="France"
                  onClick={() => changeLanguage('fr')}
                />
                <img
                  src="https://flagcdn.com/w40/gb.png"
                  srcSet="https://flagcdn.com/w40/gb.png 2x"
                  width="40"
                  alt="United Kingdom"
                  onClick={() => changeLanguage('en')}
                />
              </Languages>
            </CategorieContainer>
            <StyledLink to={'/contact'} theme={theme}>
              <CategorieContainer>
                <OptionItem theme={theme}>{t('unauthNavbar.header.contact')}</OptionItem>
              </CategorieContainer>
            </StyledLink>
          </OptionsList>
        </ScrollDownMenu>
      )}
    </IconContainer>
  );
}

export default withNamespaces()(UnauthSettings);
