import React from "react";
import ReactDOM from "react-dom/client";
import GlobalStyle from "./utils/style/GlobalStyle.jsx";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "./utils/context/ThemeProvider.js";
import Navbar from "./components/Navbar/index.jsx";
import { AuthProvider } from "./utils/context/AuthProvider.js";
import ScrollToTop from "./components/Utils/ScrollToTop/index.jsx";
import Footer from "./components/Footer/index.jsx";
import './i18n.js';
import App from "./App.jsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop>
        <ThemeProvider>
          <AuthProvider>
            <GlobalStyle />
            <Routes>
              <Route path="/*" element={<App />} />
            </Routes>
            <Navbar />
            {/* <Footer /> */}
          </AuthProvider>
        </ThemeProvider>
      </ScrollToTop>
    </Router>
  </React.StrictMode>,
);
