import styled from "styled-components";
import { useTheme } from "../../../utils/hooks/useTheme";
import colors from "../../../utils/style/colors";

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  max-width: 800px;
  width: 100%;
`;

const SearchIcon = styled.svg`
  position: absolute;
  left: 1rem;
  fill: #9e9ea7;
  width: 20px;
  height: 20px;
`;

const SearchInput = styled.input`
  margin-left: 10px;
  width: 100%;
  height: 40px;
  font-size: 1.2em;
  padding: 0 1rem;
  padding-left: 40px;
  outline: none;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
`;

function SearchBar() {
  const { theme } = useTheme();

  return (
    <Container theme={theme}>
      <SearchIcon class="icon" aria-hidden="true" viewBox="0 0 24 24">
        <g>
          <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
        </g>
      </SearchIcon>
      <SearchInput placeholder="Search your Haven!" type="search" theme={theme} />
    </Container>
  );
}

export default SearchBar;
