export function getRandomQuote() {
  // Create an array of quotes
  const quotes = [
    //JHIN QUOTES
    //1
    "I cannot be good, I must be perfection. -Jhin",
    //2
    "Art is worth the pain. -Jhin",
    //3
    "The face of your killer, is identical to mine. -Jhin",
    //4
    "Smile, everyone is watching ! -Jhin",
    //5
    "My genius will be understood, eventually. -Jhin",
    //6
    "I swear each performance is the last ..but I lie every time. -Jhin",
    //7
    "Everyone wears a mask. I just choose to create my own. -Jhin",
    //8
    "Each bullet is a song. Each bullet will be a dance. Each bullet is a piece of my soul. Each shot is a piece of me. -Jhin",
    //9
    "The moment before the shot, is painful ..It hurts so good. -Jhin",
    //10
    "Your life had no value before me. Only my actions will make you memorable. -Jhin",

    //KINDRED QUOTES
    //1
    "Are you there, dear wold? I am, little lamb. Are you sad? I am.. What does it feel like? A long hunt with no kill. -Kindred",
    //2
    "How old are we, lamb? Older than those whose footsteps are long vanished. Many years. I remember them all. -Kindred",
    //3
    "How long will we be together? Forever more. Never one… without the other. -Kindred",
    //4
    "I see water! They are called tears. Is this what it feels like to end? I do not know, for this is not our end. -Kindred",
    //5
    "Do others come back? Those who do, wish they hadn't. -Kindred",
    //6
    "There are so many ways to die. The only true death is to never live. -Kindred",
    //7
    "We are not dreams, we are the waking. All things linger… in our shadow. -Kindred",
    //8
    "They know we'll come. Yet they are never prepared. Treasure your final moments. We know your name. We have always known it. All your life has led to this moment. Its heart beats fast now. It knows… -Kindred",
    //9
    "Tomorrow is a hope, never a promise. Your time has come. Ready or not. -Kindred",
    //10
    "Lamb, tell me a story. There was once a pale man with dark hair who was very lonely. Why was it lonely? All things must meet this man, so they shunned him. Did he chase them all? He took an axe and split himself in two. Right down the middle. So he would always have a friend? So he would always have a friend. -Kindred"

  ];

  const randomIndex = Math.floor(Math.random() * quotes.length);

  return quotes[randomIndex];
}
