import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTheme } from "../../utils/hooks/useTheme";
import colors from "../../utils/style/colors";
import SearchBar from "./SearchBar";
import ProfileNavigation from "./ProfileNavigation";
import UnauthSettings from "./UnauthSettings";
import AuthIcons from "./AuthIcons";
import { useAuth } from "../../utils/hooks/useAuth";
import { useState, useEffect } from "react";
import SimpleLogoLightBeta from "../../assets/SimpleLogoWhiteBeta.png"
import SimpleLogoDarkBeta from "../../assets/SimpleLogoBeta.png"
import { withNamespaces } from 'react-i18next';

//https://vercel.com/ inspiration design

const NavbarWrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-height: 50px;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border-bottom: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
`;

const NavbarContainer = styled.div`
  height: 50px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ClassicButton = styled.button`
  margin-left: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;
  height: 40px;
  font-weight: 500;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled(ClassicButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color: ${colors.primary};

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

const Linker = styled.div`
  gap: 5px;
  display: flex;
  align-items: center;
`;

const StyledLink = styled(Link)`
    text-decoration: none;
`

const SearchBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

function Navbar({ t }) {
  const { theme } = useTheme();
  const { auth } = useAuth();

  const [avatar, setAvatar] = useState(auth.avatar);

  useEffect(() => {
    setAvatar(auth.avatar);
  }, [auth.avatar])

  return (
    <NavbarWrapper theme={theme}>
      <NavbarContainer>
        {auth?.accessToken ? (
          <>
            <Linker>
              <StyledLink to="/home">
                {theme === 'light' ?
                <img src={SimpleLogoLightBeta} alt="Haven Logo" style={{height: '50px'}} /> :
                <img src={SimpleLogoDarkBeta} alt="Haven Logo" style={{height: '50px'}} />}
              </StyledLink>
            </Linker>
            {/* <SearchBarContainer theme={theme}>
              <SearchBar theme={theme}/>
            </SearchBarContainer> */}
            <Linker>
              <AuthIcons />
              <Link to="/premium">
                <ClassicButton theme={theme}>Premium</ClassicButton>
              </Link>
              <ProfileNavigation url={avatar}/>
            </Linker>
          </>
        ) : (
          //Si l'utilisateur n'est pas authentifié.
          <>
            <StyledLink to="">
                {theme === 'light' ?
                <img src={SimpleLogoLightBeta} alt="Haven Logo" style={{height: '50px'}} /> :
                <img src={SimpleLogoDarkBeta} alt="Haven Logo" style={{height: '50px'}} />}
              </StyledLink>
            {/* <SearchBarContainer theme={theme}>
              <SearchBar theme={theme}/>
            </SearchBarContainer> */}
            <Linker>
              <Link to="/login">
                <ClassicButton theme={theme}>{t('navBar.buttons.logIn')}</ClassicButton>
              </Link>
              <Link to="/signup">
                <ActiveButton theme={theme}>{t('navBar.buttons.signUp')}</ActiveButton>
              </Link>
              <UnauthSettings />
            </Linker>
          </>
        )}
      </NavbarContainer>
    </NavbarWrapper>
  );
}

export default withNamespaces()(Navbar);
