import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { FiX } from "react-icons/fi";
import { useAuth } from "../../../utils/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosMultipart from "../../../utils/hooks/useAxiosMultipart";
import { withNamespaces } from "react-i18next";
import Icon from "../../Icon";


const EditProfileWrapper = styled.div`
  min-height: 100vh;
  max-width: 550px;
  margin: 0 auto;
  margin-top: 65px;
  display: flex;
  gap: 15px;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const FiArrowIcon = styled(FiX)`
  font-size: 1.7rem;

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light" ? colors.primaryHover : colors.secondaryHover};
  }
`;

const Title = styled.div`
  margin-left: 10px;
  font-size: 0.8rem;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`;

const LeftHeader = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;

const SaveButton = styled.button`
  margin-right: 10px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) => (theme === "light" ? colors.secondary : colors.primary)};
  background-color: ${({ theme }) => (theme === "light" ? colors.primary : colors.secondary)};
  border: 1px solid ${({ theme }) => (theme === 'light' ? colors.borderColorLight : colors.borderColorDark)};
  display: flex;
  align-items: center;
  font-weight: 400;
  text-align: center;
  padding: 6px 12px;
  height: 40px;
  font-size: 0.8rem;
  border-radius: 10px;
  white-space: nowrap;

  &:hover {
    background-color: ${({ theme }) => (theme === "light" ? colors.primaryHover : colors.secondaryHover)};
  }

`;

const InputBox = styled.div`
  height: 52px;
  margin: 50px 0;
  max-width: 500px;
  margin-left: 15px;
  margin-right: 50px;
`;

const InputTitle = styled.div`
  padding-left: 15px;
  padding-bottom: 5px;
  font-size: 0.8rem;
`;

const Inputs = styled.input`
  font-size: 0.8rem;
  height: 100%;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  font-weight: 400;
  border: 1px solid
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  text-indent: 15px;

  &::placeholder {
    font-size: 16px;
  }
`;

const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const AvatarInput = styled.input`
    font-size: 0.8rem;
`;

const ErrorMessage = styled.div`
  padding-left: 15px;
  margin-top: 20px;
  font-size: 0.8rem;
  color: red;
  text-align: center;
`

const AvatarTitle = styled.div`
    margin-top: 20px;
    padding-left: 15px;
    padding-right: 50px;
    font-size: 0.8rem;
` 

const AvatarUpload = styled.label`
  margin-top: 10px;
  margin-left: 15px;
  display: inline-block; 
  width: 200px; 
  height: 200px; 
  border: 2px dashed
    ${({ theme }) =>
      theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  cursor: pointer;
  overflow: hidden;
  transition: border-color 0.2s;
  position: relative;
  border-radius: 10px;

  input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

const Content = styled.div`
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`

function EditProfile({ t }) {
  const { theme } = useTheme();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const [name, setName] = useState(auth.username);
  const [email, setEmail] = useState(auth.email);
  const [description, setDescription] = useState(auth.description);
  const [country, setCountry] = useState(auth.country);
  const [twitter, setTwitter] = useState(auth.twitter);
  const [github, setGithub] = useState(auth.github);
  const [reddit, setReddit] = useState(auth.reddit);
  const [discord, setDiscord] = useState(auth.discord);
  const [spotify, setSpotify] = useState(auth.spotify);
  const [youtube, setYoutube] = useState(auth.youtube);
  const [twitch, setTwitch] = useState(auth.twitch);
  const [linkedin, setLinkedin] = useState(auth.linkedin);
  const [avatar, setAvatar] = useState();
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [avatarPreview, setAvatarPreview] = useState(auth.avatar !== "" ? auth.avatar : "https://institutcommotions.com/wp-content/uploads/2018/05/blank-profile-picture-973460_960_720-1.png");

  const previousAvatar = auth.avatar;
  const axiosMultipart = useAxiosMultipart();

  const handleGoBack = () => {
    navigate(-1);
  };

  function handleImage(e) {
    setAvatar(e.target.files[0]);
    
    const reader = new FileReader();

    reader.onload = (e) => {
      setAvatarPreview(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
  }

  const resetForm = () => {
    setName(auth.username);
    setEmail(auth.email);
    setDescription(auth.description);
    setCountry(auth.country);
    setTwitter(auth.twitter);
    setGithub(auth.github);
    setReddit(auth.reddit);
    setDiscord(auth.discord);
    setSpotify(auth.spotify);
    setYoutube(auth.youtube);
    setTwitch(auth.twitch);
    setLinkedin(auth.linkedin);
    setAvatar(auth.avatar); 
    setAvatarPreview(auth.avatar);
  };

  useEffect(() => {
    if (formSubmitted) {
      resetForm();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (avatar){
      formData.append("avatar", avatar, avatar.name);
      formData.append("previousAvatar", previousAvatar);
    }
    formData.append("email", email);
    formData.append("description", description);
    formData.append("country", country);
    formData.append("twitter", twitter);
    formData.append("github", github);
    formData.append("reddit", reddit);
    formData.append("discord", discord);
    formData.append("spotify", spotify);
    formData.append("youtube", youtube);
    formData.append("twitch", twitch);
    formData.append("linkedin", linkedin);

    try{
      await axiosMultipart.post("api/user/updateUser", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      })

      const updatedAuth = { ...auth };

      updatedAuth.email = email;
      updatedAuth.description = description;
      updatedAuth.country = country;
      updatedAuth.twitter = twitter;
      updatedAuth.github = github;
      updatedAuth.reddit = reddit;
      updatedAuth.discord = discord;
      updatedAuth.spotify = spotify;
      updatedAuth.youtube = youtube;
      updatedAuth.twitch = twitch;
      updatedAuth.linkedin = linkedin;

      setFormSubmitted(true);
      setAuth(updatedAuth);
      navigate(`../../Profile/${auth.username}`);

    } catch (error) {
        if (error.response) {
        
          if (error.response.status === 400) {
            setErrMsg("Invalid file format. Please select another file.");
          } else {
            setErrMsg("Server Error, please retry later");
          }

      } else {
  
        setErrMsg("Server Error, please retry later");
      }
    }
  };

  useEffect(() => {
    document.title = `Havens - Edit ${auth.username}`;
  }, [auth]);

  return (
    <EditProfileWrapper theme={theme}>
    <Header theme={theme}>
      <LeftHeader>
        <Icon>
            <FiArrowIcon theme={theme} onClick={handleGoBack}/>
        </Icon>
        <Title theme={theme}>{t('editClub.header.edit') + ' ' + name}</Title>
      </LeftHeader>
      <SaveButton
        theme={theme}
        onClick={handleSubmit}
      >{t('editProfile.header.button')}</SaveButton>
    </Header>
      { errMsg && (
        <ErrorMessage>{errMsg}</ErrorMessage>
      )}
    <Content theme={theme}>
      <AvatarTitle theme={theme}>{t('editProfile.header.avatar')}</AvatarTitle>
      <AvatarUpload theme={theme}>

        {avatarPreview ? (
          <AvatarImage src={avatarPreview} alt="Avatar" />
        ) : (
          <p>{t('editProfile.inputs.avatar')}</p>
        )}

        <AvatarInput
          type="file"
          name="avatar"
          onChange={handleImage}
        />

      </AvatarUpload>
        <InputBox>
          <InputTitle>{t('editProfile.header.name')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="name"
            placeholder={t('editProfile.inputs.name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.email')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="email"
            placeholder={t('editProfile.inputs.email')}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.desc')}</InputTitle>
          <Inputs
            theme={theme}
            type="message"
            name="description"
            placeholder={t('editProfile.inputs.desc')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.country')}</InputTitle>
          <Inputs
            theme={theme}
            type="message"
            name="country"
            placeholder={t('editProfile.inputs.country')}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.twitter')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="twitter"
            placeholder={t('editProfile.inputs.twitter')}
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.github')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="github"
            placeholder={t('editProfile.inputs.github')}
            value={github}
            onChange={(e) => setGithub(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.reddit')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="reddit"
            placeholder={t('editProfile.inputs.reddit')}
            value={reddit}
            onChange={(e) => setReddit(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.discord')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="discord"
            placeholder={t('editProfile.inputs.discord')}
            value={discord}
            onChange={(e) => setDiscord(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.spotify')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="spotify"
            placeholder={t('editProfile.inputs.spotify')}
            value={spotify}
            onChange={(e) => setSpotify(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.youtube')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="youtube"
            placeholder={t('editProfile.inputs.youtube')}
            value={youtube}
            onChange={(e) => setYoutube(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.twitch')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="twitch"
            placeholder={t('editProfile.inputs.twitch')}
            value={twitch}
            onChange={(e) => setTwitch(e.target.value)}
          />
        </InputBox>
        <InputBox>
          <InputTitle>{t('editProfile.header.linkedin')}</InputTitle>
          <Inputs
            theme={theme}
            type="text"
            name="linkedin"
            placeholder={t('editProfile.inputs.linkedin')}
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </InputBox>
        </Content>
    </EditProfileWrapper>
  );
}

export default withNamespaces()(EditProfile);
