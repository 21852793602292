import { SocialIcon } from "react-social-icons";
import styled from "styled-components";
import { useTheme } from "../../../utils/hooks/useTheme";
import colors from "../../../utils/style/colors";
import { withNamespaces } from "react-i18next";

const Socials = styled.div`
    animation: none;
`

const InfoFalseText = styled.div`
    font-style: italic;
    font-size: 0.8rem;
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText}; 
    text-decoration: underline;
    cursor: pointer;
`

function SocialLinks({ info, toggleInfo, twitter, github, reddit, discord, spotify, youtube, twitch, linkedin, t }){
    const { theme } = useTheme();

    return (
        <>
        {info ? (
            <Socials>
                {twitter && (
                    <SocialIcon 
                        network="x" 
                        url={`https://twitter.com/${twitter}`} 
                        style={{ width: "30px", height: "30px" }}
                        target="_blank"
                    />
                )}
                {github && (
                    <SocialIcon 
                        network="github" 
                        url={`https://github.com/${github}`}
                        style={{ width: "30px", height: "30px" }} 
                        target="_blank"
                    />
                )}
                {reddit && (
                    <SocialIcon 
                        network="reddit" 
                        url={`https://www.reddit.com/user/${reddit}`}
                        style={{ width: "30px", height: "30px" }} 
                        target="_blank"
                    />
                )}
                {discord && (
                    <SocialIcon 
                        network="discord" 
                        url={discord}
                        style={{ width: "30px", height: "30px" }} 
                        target="_blank"
                    />
                )}
                {spotify && (
                    <SocialIcon 
                        network="spotify" 
                        url={spotify}
                        style={{ width: "30px", height: "30px" }} 
                        target="_blank"
                    />
                )}
                {youtube && (
                    <SocialIcon 
                        network="youtube" 
                        url={`https://www.youtube.com/@${youtube}`} 
                        style={{ width: "30px", height: "30px" }}
                        target="_blank"
                    />
                )}
                {twitch && (
                    <SocialIcon 
                        network="twitch" 
                        url={`https://www.twitch.tv/${twitch}`} 
                        style={{ width: "30px", height: "30px" }}
                        target="_blank"
                    />
                )}
                {linkedin && (
                    <SocialIcon 
                        network="linkedin" 
                        url={`https://www.linkedin.com/in/${linkedin}`}
                        style={{ width: "30px", height: "30px" }}
                        target="_blank"
                    />
                )}
            </Socials>
        ) : (
            <InfoFalseText onClick={toggleInfo} theme={theme}>{t('profile.links.social')}</InfoFalseText>
        )}
        </>
    );
}

export default withNamespaces()(SocialLinks);