import { FiUser, FiCompass, FiBell} from "react-icons/fi";
import styled, {css} from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { Link } from "react-router-dom";
import { useAuth } from "../../../utils/hooks/useAuth";

const IconStyle = css`
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
  display: flex;
  font-size: 28px;
  padding:5px ;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({theme}) => theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  &:hover {
    background-color: ${({ theme }) => theme === "light" ? colors.primaryHover : colors.secondaryHover};
  }
`;

const BiUserIcon = styled(FiUser)`
  ${IconStyle}
`;
const CompassIcon = styled(FiCompass)`
  ${IconStyle}
`;
const BellIcon = styled(FiBell)`
  ${IconStyle}
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;  
  height: 100%;
`;

const AuthIconsContainer = styled.div`
  margin-left: 10px;
  gap: 5px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    display: none;
  }
`;

function AuthIcons() {
  const { auth } = useAuth();
  const { theme } = useTheme();

  return (
    <AuthIconsContainer theme={theme}>
      <StyledLink theme={theme} to="/notifications">
        <BellIcon theme={theme} />
      </StyledLink>
      <StyledLink theme={theme} to={`/profile/${auth.username}`}>
        <BiUserIcon theme={theme} />
      </StyledLink>
      <StyledLink theme={theme} to={`/discover/clubs`}>
        <CompassIcon theme={theme} />
      </StyledLink>
    </AuthIconsContainer>
  );
}

export default AuthIcons;
