import styled from "styled-components"
import colors from "../../../utils/style/colors"
import { useTheme } from "../../../utils/hooks/useTheme";
import Icon from "../../Icon";
import { FiBell, FiBellOff, FiLink, FiStar } from "react-icons/fi";
import { useState } from "react";
import { Link } from "react-router-dom";
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import bannerUnauthLight from "../../../assets/bannerLight.png";
import bannerUnauthDark from "../../../assets/bannerDark.png";
import { withNamespaces } from "react-i18next";
import MentionText from "../../Utils/MentionText";
import { truncateString } from "../../../utils/textAnalyser/truncateString";

const ClubContainer = styled.div`
    border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-bottom: none;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    max-height: 400px;
`

const Banner = styled.div`
  max-width: 100%;
  height: 250px;
  background-image: url(${(props) => props.bannerUrl});
  background-size: cover;
  background-position: center;
`;


const ProfileImage = styled.img`
    width: 150px;
    height: 150px;
    border-radius: 10px;
    transform: translateY(-50%); 
    background-color: ${colors.profileBgColor};
`;

const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
    gap: 5px;
`;

const UserName = styled.div`
    margin: 0;
    font-weight: 600;
    font-size: 1.5rem;
    white-space: nowrap;
`;

const Bio = styled.p`
    margin: 0;
    font-size: 0.8rem;
`;

const ProfileHeader = styled.div`
    margin-left: 30px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
`

const LeftHeader = styled.div`
    display: flex;
    gap: 10px;
`

const RightHeader = styled.div`
    display: flex;
    padding-right: 10px;
    gap: 5px;
`

const ClassicButton = styled.button`
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 10px;
  white-space: nowrap;
  height: 42px;
  font-weight: 500;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled(ClassicButton)`
    background-color: ${colors.extra};
    border: 1px solid ${colors.extra};
    color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};

    &:hover{
      background-color: ${colors.extra};
      opacity: 0.8;
    }
`

const Count = styled.div`
    font-size: 0.8rem;
    cursor: pointer;
`

const SpanText = styled.span`
    color: ${({theme}) => theme === 'light' ? colors.secondary : colors.primary};
    font-size: 1.5rem;
`

const SpanCount = styled(SpanText)`
    font-size: 0.8rem;
`

const BellIcon = styled(FiBell)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const NotBellIcon = styled(FiBellOff)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 40px;
  max-width: 100%;
  gap: 5px;
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
  display: flex;
`;

const ClubWrapper = styled.div`
    
`


function ClubProfile({ bannerUrl, profileImageUrl, club, desc, memberCount, createdAt, isMember, isOwner, toggleJoinStatus, clubNotFound, toggleClubStatus, t }){

    const {theme} = useTheme();

    const [bellStatus, setBellStatus] = useState(true);

    function toggleBellStatus() {
        setBellStatus((prevStatus) => !prevStatus);
    }

    return (
        <ClubWrapper>
            <ClubContainer theme={theme}>
                <Banner bannerUrl={bannerUrl ? bannerUrl : (theme === 'light' ? bannerUnauthLight : bannerUnauthDark)} alt={"Club's Banner"} />
                <ProfileHeader>
                    <LeftHeader>
                        <ProfileImage src={profileImageUrl ? profileImageUrl : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)} alt="Profile" />
                        <ProfileDetails theme={theme}>
                            <UserName><SpanText theme={theme}>/{club}</SpanText></UserName>
                            <Bio>
                                {desc ? <MentionText text={truncateString(desc, 120)} /> : t('club.header.desc')}
                            </Bio>
                            <Count onClick={() => toggleClubStatus("Members")}>
                                <SpanCount theme={theme}>{memberCount} </SpanCount>
                                {memberCount > 1 ? t('club.header.members') : t('club.header.member')}
                            </Count>
                        </ProfileDetails>
                    </LeftHeader>
                    <RightHeader>
                        <Icons>
                            <Icon>
                                <FiLink theme={theme}/>
                            </Icon>
                            <Icon>
                                <FiStar theme={theme}/>
                            </Icon>
                            {bellStatus && (
                            <Icon>
                                <BellIcon theme={theme} onClick={toggleBellStatus} />
                            </Icon>
                            )}
                            {!bellStatus && (
                            <Icon>
                                <NotBellIcon theme={theme} onClick={toggleBellStatus} />
                            </Icon>
                            )}
                        </Icons>
                        {isOwner ? (
                            <StyledLink to={`../../edit/club/${club}`}>
                                <ClassicButton theme={theme}>{t('club.header.edit')}</ClassicButton>
                            </StyledLink>
                        ) : ((club !== 'everyone' || clubNotFound) ? (
                            isMember ? (
                                <ClassicButton theme={theme} onClick={toggleJoinStatus}>
                                    {t('club.header.leave')}
                                </ClassicButton>
                            ) : (
                                <ActiveButton theme={theme} onClick={toggleJoinStatus}>
                                    {t('club.header.join')}
                                </ActiveButton>
                            )
                        ) : (
                            club === 'everyone' ? (
                                <ActiveButton theme={theme} onClick={toggleJoinStatus}>
                                    Everyone
                                </ActiveButton>
                            ) : (
                                <ClassicButton theme={theme} onClick={toggleJoinStatus}>
                                    Nothing
                                </ClassicButton>
                            )
                        )
                        )}
                    </RightHeader>
                </ProfileHeader>
            </ClubContainer>
        </ClubWrapper>
    )
};

export default withNamespaces()(ClubProfile);
