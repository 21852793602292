import colors from "../../utils/style/colors";
import styled from "styled-components";
import NavHeader from "../../components/Header/NavHeader";
import { withNamespaces } from "react-i18next";
import { useTheme } from "../../utils/hooks/useTheme";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useState, useEffect} from "react";
import { useAuth } from "../../utils/hooks/useAuth";
import { useParams } from "react-router-dom";
import UserCard from "../../components/User/UserCard";

const OverviewWrapper = styled.div`
    margin: 0 auto;
    margin-top: 65px;
    max-width: 550px;
`

const OverviewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const CardContainer = styled.div`
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 200px;
  padding: 15px;
  box-sizing: border-box;
`;

function LikesOverview({ t }){

    const {theme} = useTheme();
    const {auth} = useAuth();
    const {post_id} = useParams();

    const axiosPrivate = useAxiosPrivate();

    const [likes, setLikes] = useState([]);

    const GETLIKES_URL = `api/post/getLikes/${post_id}`;

    useEffect(() => {
        if (auth.accessToken) {
            try {
                axiosPrivate
                .get(GETLIKES_URL)
                .then((response) => {
                    setLikes(response.data.likes);
                })
                .catch((err) => {
                    console.error("Erreur lors de la requête :", err);

                    if (err.response) {
                        console.log(err.response);
                        console.log(err.response.data);
                        console.log(err.response.status);
                    } else {
                        console.error("Erreur générique :", err.message);
                    }
                });
            } catch (err) {
                console.error("Erreur lors de la requête :", err);
            }
        }
    }, []);

    return (
        <OverviewWrapper>
            <OverviewContainer>
                <NavHeader mention={t('utils.header.message')}/>
                    <CardContainer theme={theme}>
                        {likes.length !== 0 ? (
                            likes.map(({ username, avatar, description, followers_count }, index) => (
                                <UserCard 
                                    small={true}
                                    key={username} 
                                    rank={index+1}
                                    username={username}
                                    avatar={avatar}
                                    description={description}
                                    followers_count={followers_count}
                                />
                            ))
                        ) : (
                            <UserCard 
                                    small={true}
                                    key={'havens'} 
                                    rank={1}
                                    username={'havens'}
                                    avatar={''}
                                    description={"Let's be honest, it's not your best post..."}
                                    followers_count={0}
                                />
                        )}
                    </CardContainer>
            </OverviewContainer>
        </OverviewWrapper>
    );
}

export default withNamespaces()(LikesOverview);