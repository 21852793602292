import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTheme } from "../../../utils/hooks/useTheme";
import colors from "../../../utils/style/colors";
import { useState } from "react";
import { BiSolidBadgeCheck } from "react-icons/bi";
import { FiBell, FiBellOff, FiMail, FiLink } from "react-icons/fi";
import MentionText from "../../Utils/MentionText";
import Error from "../../Utils/Error";
import SocialLinks from "../../User/SocialLinks"
import Icon from "../../Icon";
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import { withNamespaces } from "react-i18next";

const ProfileContainer = styled.div`
  max-width: 250px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    flex-direction: row;
  }
`;

const Username = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  font-weight: 600;
`;

const Mention = styled.div`
  padding-bottom: 5px;
  font-size: 0.8rem;
`;

const CertificationIcon = styled(BiSolidBadgeCheck)`
  font-size: 1.5rem;
  vertical-align: bottom;
`;

const Bio = styled.div`
  max-width: 100%;
  font-size: 0.8rem;
`;

const Stats = styled.div`
  display: flex;
  font-size: 0.8rem;
  gap: 15px;
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
`;

const ProfileWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  margin-top: 15px;
  position: sticky;
  display: flex;
`;

const Counter = styled.span`
  font-size: 0.8rem;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
`;

const Avatar = styled.img`
  border-radius: 10%;
  width: 10rem;
  height: 10rem;

  @media (max-width: 650px) {
    max-width: 100px;
    max-height: 100px;
  }

  /* @media (max-width: 750px) {
    max-width: 150px;
    max-height: 150px;
  } */
`;

const MessageIcon = styled(FiMail)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const InfoIcon = styled(FiLink)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
` 

const BellIcon = styled(FiBell)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const NotBellIcon = styled(FiBellOff)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
  display: flex;
`;

const Icons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  max-height: 40px;
  max-width: 100%;
`;

const ClassicButton = styled.button`
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  height: 42px;
  border-radius: 0.25rem;
  white-space: nowrap;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.primaryHover : colors.secondaryHover};
    }
`;

const ActiveButton = styled.button`
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  background-color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorDark : colors.borderColorLight};
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  padding: 6px 12px;
  font-size: 16px;
  border-radius: 0.25rem;
  white-space: nowrap;

  &:hover{
      background-color: ${({theme}) => theme === 'light' ? colors.secondaryHover : colors.primaryHover};
    }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  max-width: 100%;
`

const BioText = styled.div`
  font-size: 0.8rem;
`

const Stat = styled.div`
  font-size: 0.8rem;
`
const Content = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
`

const FollowsLink = styled(Link)`
  color: ${({theme}) => theme === 'light' ? colors.primaryText : colors.secondaryText};
  text-decoration: none;
  font-size: 0.8rem;

  &:hover{
    text-decoration: underline;
  }
`

function UserProfile({
  userData: {
    auth,
    mention,
    avatar,
    desc,
    country,
    twitter,
    github,
    reddit,
    discord,
    spotify,
    youtube,
    twitch,
    linkedin,
    followCount,
    followingCount,
    toggleInfo,
    follow,
    toggleFollow,
    info,
    
  },
  t
}){
  const { theme } = useTheme();

  const [bellStatus, setBellStatus] = useState(true);

  function toggleBellStatus() {
    setBellStatus((prevStatus) => !prevStatus);
  }

  return auth.accessToken ? (
    <ProfileWrapper theme={theme}>
      <ProfileContainer theme={theme}>
          <Avatar
            theme={theme}
            src={avatar ? avatar : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)}
            alt={`${t('profile.header.avatar')}` + ' ' + `${mention}`}
          />
          <Content>
            <Icons>
              {bellStatus && (
                <Icon>
                  <BellIcon theme={theme} onClick={toggleBellStatus} />
                </Icon>
              )}
              {!bellStatus && (
                <Icon>
                  <NotBellIcon theme={theme} onClick={toggleBellStatus} />
                </Icon>
              )}
              <StyledLink theme={theme} to={`/messages/${mention}`}>
              <Icon><MessageIcon theme={theme} /></Icon>
              </StyledLink>
              <Icon><InfoIcon theme={theme} onClick={toggleInfo} /></Icon>
              {mention === auth.username ? (
                <StyledLink theme={theme} to="/settings/account">
                  <ClassicButton theme={theme}>
                    {t('profile.header.edit')}
                  </ClassicButton>
                </StyledLink>
              ) : (
                !follow ? (
                  <ActiveButton theme={theme} onClick={toggleFollow}>
                    {t('profile.header.follow')}
                  </ActiveButton>
                ) : (
                  <ClassicButton theme={theme} onClick={toggleFollow}>
                    {t('profile.header.unfollow')}
                  </ClassicButton>
                )
              )}
            </Icons>
            <div>
              <Username>
                {mention}{mention === "Havens" && (<CertificationIcon />)}
              </Username>
              <Mention><MentionText text={"@" + mention}/></Mention>
            </div>
            { desc && (
              <Bio><MentionText text={desc}/></Bio>
            )}
            <Stats>
              <Stat>
                <Counter theme={theme}>{followingCount}</Counter> <FollowsLink theme={theme} to={`../../${mention}/overview`}>{t('profile.header.following')}</FollowsLink>
              </Stat>
              <Stat>
                <Counter theme={theme}>{followCount}</Counter> <FollowsLink theme={theme} to={`../../${mention}/overview`}>{t('profile.header.followers')}</FollowsLink>
              </Stat>
            </Stats>
            <Stats>
            {country !== '' && (
              <BioText>{t('profile.header.from')} <Counter theme={theme}>{country}</Counter></BioText>
            )}
            </Stats>
            <SocialIcons>
              <SocialLinks 
                info={info} 
                toggleInfo={toggleInfo}
                twitter={twitter}
                github={github}
                reddit={reddit}
                discord={discord}
                spotify={spotify}
                youtube={youtube}
                twitch={twitch}
                linkedin={linkedin}
              />
            </SocialIcons>
          </Content>
      </ProfileContainer>
    </ProfileWrapper>
  ) : (
    <Error />
  );
}

export default withNamespaces()(UserProfile);
