import colors from "../../utils/style/colors";
import styled from "styled-components";
import NavHeader from "../../components/Header/NavHeader";
import { withNamespaces } from "react-i18next";
import { useTheme } from "../../utils/hooks/useTheme";
import useAxiosPrivate from "../../utils/hooks/useAxiosPrivate";
import { useState, useEffect} from "react";
import { useAuth } from "../../utils/hooks/useAuth";
import { useParams } from "react-router-dom";
import UserCard from "../../components/User/UserCard";
import FollowsSelector from "../../components/Selector/FollowsSelector";

const OverviewWrapper = styled.div`
    margin: 0 auto;
    margin-top: 65px;
    max-width: 550px;
`

const OverviewContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
`

const CardContainer = styled.div`
  border: 1px solid ${({theme}) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  width: 100%;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 20px;
  align-content: center;
  margin: 0 auto;
  margin-bottom: 200px;
  padding: 15px;
  box-sizing: border-box;
`;

function FollowsOverview({ t }){

    const {theme} = useTheme();
    const {auth} = useAuth();
    const {username} = useParams();

    const axiosPrivate = useAxiosPrivate();

    const [followers, setFollowers] = useState([]);
    const [following, setFollowing] = useState([]);
    const [followsStatue, setFollowsStatue] = useState('following');

    const GETFOLLOWERS_URL = `api/user/getFollowers/${username}`;
    const GETFOLLOWING_URL = `api/user/getFollowing/${username}`;

    const toggleFollowsStatue = (statue) => {
        setFollowsStatue(statue);
    };

    useEffect(() => {
        if (auth.accessToken) {
            try {
                axiosPrivate
                .get(GETFOLLOWERS_URL)
                .then((response) => {
                    setFollowers(response.data.followers);
                })
                .catch((err) => {
                    console.error("Erreur lors de la requête :", err);

                    if (err.response) {
                        console.log(err.response);
                        console.log(err.response.data);
                        console.log(err.response.status);
                    } else {
                        console.error("Erreur générique :", err.message);
                    }
                });
            } catch (err) {
                console.error("Erreur lors de la requête :", err);
            }
        }
    }, [followsStatue === "followers"]);

    useEffect(() => {
        if (auth.accessToken) {
            try {
                axiosPrivate
                .get(GETFOLLOWING_URL)
                .then((response) => {
                    setFollowing(response.data.following);
                })
                .catch((err) => {
                    console.error("Erreur lors de la requête :", err);

                    if (err.response) {
                        console.log(err.response);
                        console.log(err.response.data);
                        console.log(err.response.status);
                    } else {
                        console.error("Erreur générique :", err.message);
                    }
                });
            } catch (err) {
                console.error("Erreur lors de la requête :", err);
            }
        }
    }, [followsStatue === "following"]);

    return (
        <OverviewWrapper>
            <OverviewContainer>
                <NavHeader mention={t('utils.header.message')}/>
                <FollowsSelector followsStatue={followsStatue} toggleFollowsStatue={toggleFollowsStatue} />
                    {followsStatue === "following" && (
                        following.length > 0 &&
                        <CardContainer theme={theme}>
                        {following.map(({ username, avatar, description, followers_count }, index) => (
                        <UserCard 
                            small={true}
                            key={username} 
                            rank={index+1}
                            username={username}
                            avatar={avatar}
                            description={description}
                            followers_count={followers_count}
                        />
                        ))}
                        </CardContainer>
                    )}
                    {followsStatue === "followers" && (
                        followers.length > 0 &&
                        <CardContainer theme={theme}>
                        {followers.map(({ username, avatar, description, followers_count }, index) => (
                        <UserCard 
                            small={true}
                            key={username} 
                            rank={index+1}
                            username={username}
                            avatar={avatar}
                            description={description}
                            followers_count={followers_count}
                        />
                        ))}
                        </CardContainer>
                    )}
            </OverviewContainer>
        </OverviewWrapper>
    );
}

export default withNamespaces()(FollowsOverview);