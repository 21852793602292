const colors = {
  primary: "white",
  secondary: "#090E16",
  primaryText: "#666666",
  primaryHover: "#E6E6E6",
  secondaryText: "#898A8C",
  secondaryHover: "#464648",
  borderColorDark: "#343536",
  borderColorLight: "#EDEFF1",
  extra: '#35C262',
};

export default colors;