import styled from "styled-components";
import colors from "../../../utils/style/colors";
import { useTheme } from "../../../utils/hooks/useTheme";
import { AiOutlineDown } from "react-icons/ai";
import { useEffect, useState } from "react";
import Avatar from "../../Avatar";
import { Link, useNavigate } from "react-router-dom";
import Switch from "../../Utils/Switch";
import { useAuth } from "../../../utils/hooks/useAuth";
import axios from "../../../api/axios";
import i18n from '../../../i18n';
import { withNamespaces } from "react-i18next";
import { truncateString } from "../../../utils/textAnalyser/truncateString";

const LOGOUT_URL = "/api/auth/logout";

const Container = styled.div`
  position: relative;
  max-width: 150px;
`;

const Header = styled.div`
  width: 150px;
  display: flex;
  align-items: center;
  height: 38px;
  border: 1px solid;
  border-color: ${({ theme }) =>
    theme === "light" ? colors.borderColorLight : colors.borderColorDark};
  border-radius: 10px;
  font-weight: 500;
  line-height: 1.5;
`;

const ScrollDownIcon = styled(AiOutlineDown)`
  font-size: 1rem;
  vertical-align: bottom;
  margin-left: auto;
  padding-right: 5px;
  cursor: pointer;
  fill: ${({ theme }) =>
    theme === "light" ? colors.secondaryContrast : colors.primaryContrast};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding-left: 5px;
  justify-content: center;
  white-space: nowrap;
`;

const ScrollDownMenu = styled.div`
  width: 150px;
  padding: 5px;
  margin: 0;
  position: absolute;
  top: 100%;
  transform: translateX(-8px);
  background-color: ${({ theme }) =>
    theme === "light" ? colors.primary : colors.secondary};
  outline: 1px solid;
  outline-color: ${({ theme }) =>
    theme === "light" ? colors.primaryHover : colors.secondaryHover};
  border-radius: 10px;
  transform-origin: top center;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;


const OptionsList = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const OptionItem = styled.div`
  font-size: 0.8rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) =>
    theme === "light" ? colors.primaryText : colors.secondaryText};

  &:hover {
    background-color: ${({ theme }) =>
      theme === "light"
        ? colors.primaryContrastHover
        : colors.secondaryContrastHover};
  }
`;

const OptionCategorieTitle = styled.div`
  padding-bottom: 5px;
`;

const CategorieContainer = styled.div`
  display: flex;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  width: 90%;
`;

const ContentTitle = styled.div`
  padding-left: 5px;
  font-size: 0.8rem;
`;

const LogOutButton = styled.button`
  margin-top: 5px;
  margin-left: 5px;
  margin-bottom: 5px;
  cursor: pointer;
  outline: 0;
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  background-color: red;
  color: white;
  border-color: #0d6efd;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  border: 1px solid transparent;
  padding: 6px 12px;
  font-size: 15px;
  border-radius: 0.25rem;
  white-space: nowrap;
`;

const Languages = styled.div`
  display: flex;
  gap: 15px;
`;

function ProfileNavigation( {t} ) {
  const { theme, toggleTheme } = useTheme();
  const [isChecked, setIsChecked] = useState("light");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const changeLanguage = (ln) => {
    i18n.changeLanguage(ln);
  }

  const handleSwitchChange = () => {
    toggleTheme();
    setIsChecked(!isChecked);
  };

  const optionsbis = ["Dark mode"];

  const logout = async () => {
    setAuth({});
    navigate("/home");
  };

  return (
    <Container>
      <Header theme={theme} onClick={toggleMenu}>
        <Content>
          <Avatar theme={theme} size={25} url={auth.avatar}/>
          <ContentTitle>{truncateString(auth.username, 8)}</ContentTitle>
        </Content>
        <ScrollDownIcon theme={theme} />
      </Header>
      {isMenuOpen && (
        <ScrollDownMenu theme={theme}>
          <OptionCategorieTitle>{t('profileNavbar.header.links')}</OptionCategorieTitle>
          <OptionsList>
            <CategorieContainer>
              <StyledLink to={"Contact"}>
                <OptionItem theme={theme}>
                  {t('profileNavbar.header.contact')}
                </OptionItem>
              </StyledLink>
            </CategorieContainer>
            <CategorieContainer>
              <StyledLink to={`Profile/${auth.username}`}>
                <OptionItem theme={theme}>
                  {t('profileNavbar.header.profile')}
                </OptionItem>
              </StyledLink>
            </CategorieContainer>
            <CategorieContainer>
              <StyledLink to={"Discover/clubs"}>
                <OptionItem theme={theme}>
                  {t('profileNavbar.header.discover')}
                </OptionItem>
              </StyledLink>
            </CategorieContainer>
          </OptionsList>
          <OptionCategorieTitle>{t('profileNavbar.header.options')}</OptionCategorieTitle>
          <OptionsList>
            <Languages>
              <img
                src="https://flagcdn.com/w40/fr.png"
                srcSet="https://flagcdn.com/w40/fr.png 2x"
                width="40"
                alt="France"
                onClick={() => changeLanguage('fr')}
              />
              <img
                src="https://flagcdn.com/w40/gb.png"
                srcSet="https://flagcdn.com/w40/gb.png 2x"
                width="40"
                alt="United Kingdom"
                onClick={() => changeLanguage('en')}
              />
            </Languages>
          </OptionsList>
          <OptionsList>
            <CategorieContainer>
              <OptionItem theme={theme}>
                {t('profileNavbar.header.mode')}
                <Switch
                  handleChange={handleSwitchChange}
                  isChecked={isChecked}
                />
              </OptionItem>
            </CategorieContainer>
          </OptionsList>
          <LogOutButton theme={theme} onClick={logout}>
            {t('profileNavbar.header.logOut')}
          </LogOutButton>
        </ScrollDownMenu>
      )}
    </Container>
  );
}

export default withNamespaces()(ProfileNavigation);
