import styled from "styled-components"
import { useTheme } from "../../../utils/hooks/useTheme"
import colors from "../../../utils/style/colors"
import { useEffect, useState } from "react";
import useAxiosPrivate from "../../../utils/hooks/useAxiosPrivate";
import { useAuth } from "../../../utils/hooks/useAuth";
import avatarUnauthLight from "../../../assets/avatarLight.png";
import avatarUnauthDark from "../../../assets/avatarDark.png";
import { FiClipboard } from "react-icons/fi";
import Icon from "../../Icon";
import { Link } from "react-router-dom";

const ClubsIcon = styled(FiClipboard)`
  cursor: pointer;
  color: ${({ theme }) => theme === "light" ? colors.primaryText : colors.secondaryText};
`;

const VerticalWrapper = styled.div`
    position: sticky;
    border: 1px solid ${({ theme }) => theme === 'light' ? colors.borderColorLight : colors.borderColorDark};
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    @media (max-width: 1200px) {
        display: none;
    }
`;

const StyledLink = styled(Link)`
  color: ${({ theme }) =>
    theme === "light" ? colors.secondary : colors.primary};
  text-decoration: none;
`;

const VerticalContainer = styled.div`
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
`;

const ClubAvatar = styled.img`
    font-size: 0.8rem;
    width: 40px;
    height: 40px;
    border-radius: 10px; 
`;

function VerticalClubs(){

    const {theme} = useTheme();
    const {auth} = useAuth();

    const [clubsAvatar, setClubsAvatar] = useState([]);

    const axiosPrivate = useAxiosPrivate();

    const GETALLCLUBSJOINED_URL = "/api/club/getClubsJoinedIcons";

    useEffect(() => {
        if (auth.username) {
            try {
            axiosPrivate
                .get(GETALLCLUBSJOINED_URL, {})
                .then((response) => {
                    setClubsAvatar(response.data.clubs);
                })
                .catch((err) => {
                // Gérez les erreurs ici
                console.error("Erreur lors de la requête :", err);

                if (err.response) {
                    console.log(err.response);
                    console.log(err.response.data);
                    console.log(err.response.status);
                    console.log(err.response.headers);
                } else {
                    console.error("Erreur générique :", err.message);
                }
                });
            } catch (err) {
            console.error("Erreur lors de la requête :", err);
            }
        } else {
            setClubsAvatar([]);
        }
    }, []);

    return (
        <VerticalWrapper theme={theme}>
            <VerticalContainer>
                <Icon><ClubsIcon theme={theme} /></Icon>
                {clubsAvatar.map((avatar, index) => (
                    <StyledLink theme={theme} to={`/club/${avatar.club}`}>
                        <ClubAvatar key={index} src={avatar.avatar !== "" ? avatar.avatar : (theme === 'light' ? avatarUnauthLight : avatarUnauthDark)} alt={`Club Avatar ${index}`} />
                    </StyledLink>
                ))}
            </VerticalContainer>
        </VerticalWrapper>
    )
};

export default VerticalClubs